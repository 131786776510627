@use "global" as *;

// ----------------------------------------
// Text Align
// ----------------------------------------
.u-tal { text-align: left; }
.u-tal-pc { @include media(pc) {text-align: left; }}
.u-tal-sp { @include media(sp) {text-align: left; }}

.u-tac { text-align: center; }
.u-tac-pc { @include media(pc) {text-align: center; }}
.u-tac-sp { @include media(sp) {text-align: center; }}

.u-tar { text-align: right; }
.u-tar-pc { @include media(pc) {text-align: right; }}
.u-tar-sp { @include media(sp) {text-align: right; }}


// ----------------------------------------
// Float
// ----------------------------------------
.u-fl { float: left; }
.u-fl-pc { @include media(pc) {float: left; }}
.u-fl-sp { @include media(sp) {float: left; }}

.u-fr { float: right; }
.u-fr-pc { @include media(pc) {float: right; }}
.u-fr-sp { @include media(sp) {float: right; }}


// ----------------------------------------
// Font Weight
// ----------------------------------------
.u-fw-300 { font-weight: 300; }
.u-fw-300-pc { @include media(pc) {font-weight: 300; }}
.u-fw-300-sp { @include media(sp) {font-weight: 300; }}

.u-fw-400 { font-weight: 400; }
.u-fw-400-pc { @include media(pc) {font-weight: 400; }}
.u-fw-400-sp { @include media(sp) {font-weight: 400; }}

.u-fw-500 { font-weight: 500; }
.u-fw-500-pc { @include media(pc) {font-weight: 500; }}
.u-fw-500-sp { @include media(sp) {font-weight: 500; }}

.u-fw-600 { font-weight: 600; }
.u-fw-600-pc { @include media(pc) {font-weight: 600; }}
.u-fw-600-sp { @include media(sp) {font-weight: 600; }}

.u-fw-700 { font-weight: 700; }
.u-fw-700-pc { @include media(pc) {font-weight: 700; }}
.u-fw-700-sp { @include media(sp) {font-weight: 700; }}

.u-fw-800 { font-weight: 800; }
.u-fw-800-pc { @include media(pc) {font-weight: 800; }}
.u-fw-800-sp { @include media(sp) {font-weight: 800; }}

.u-fw-900 { font-weight: 900; }
.u-fw-900-pc { @include media(pc) {font-weight: 900; }}
.u-fw-900-sp { @include media(sp) {font-weight: 900; }}


// ----------------------------------------
// Clearfix
// ----------------------------------------
%clearfix {
  &:before,
  &:after{
    content: " ";
    display: table;
  }

  &:after{ clear: both; }

  &{ *zoom: 1; }
}

.u-cf {
  @extend %clearfix;
}


// ----------------------------------------
// Position
// ----------------------------------------
.u-por { position: relative; }
.u-por-pc { @include media(pc) { position: relative; }}
.u-por-sp { @include media(sp) { position: relative; }}
.u-poa { position: absolute; }
.u-poa-pc { @include media(pc) { position: absolute; }}
.u-poa-sp { @include media(sp) { position: absolute; }}
.u-pof { position: fixed; }
.u-pof-pc { @include media(pc) { position: fixed; }}
.u-pof-sp { @include media(sp) { position: fixed; }}


// ----------------------------------------
// Display None
// ----------------------------------------
.u-pc { @include media(sp) { display: none; } }
.u-sp { @include media(pc) { display: none; } }


// ----------------------------------------
// Font Size
// ----------------------------------------
.u-fz-10 { @include media(pc) { font-size: 10px; } @include media(sp) { font-size: sp(10); } }
.u-fz-11 { @include media(pc) { font-size: 11px; } @include media(sp) { font-size: sp(11); } }
.u-fz-12 { @include media(pc) { font-size: 12px; } @include media(sp) { font-size: sp(12); } }
.u-fz-13 { @include media(pc) { font-size: 13px; } @include media(sp) { font-size: sp(13); } }
.u-fz-14 { @include media(pc) { font-size: 14px; } @include media(sp) { font-size: sp(14); } }
.u-fz-15 { @include media(pc) { font-size: 15px; } @include media(sp) { font-size: sp(15); } }
.u-fz-16 { @include media(pc) { font-size: 16px; } @include media(sp) { font-size: sp(16); } }
.u-fz-17 { @include media(pc) { font-size: 17px; } @include media(sp) { font-size: sp(17); } }
.u-fz-18 { @include media(pc) { font-size: 18px; } @include media(sp) { font-size: sp(18); } }
.u-fz-19 { @include media(pc) { font-size: 19px; } @include media(sp) { font-size: sp(19); } }
.u-fz-20 { @include media(pc) { font-size: 20px; } @include media(sp) { font-size: sp(20); } }
.u-fz-10-pc { @include media(pc) { font-size: 10px; } }
.u-fz-11-pc { @include media(pc) { font-size: 11px; } }
.u-fz-12-pc { @include media(pc) { font-size: 12px; } }
.u-fz-13-pc { @include media(pc) { font-size: 13px; } }
.u-fz-14-pc { @include media(pc) { font-size: 14px; } }
.u-fz-15-pc { @include media(pc) { font-size: 15px; } }
.u-fz-16-pc { @include media(pc) { font-size: 16px; } }
.u-fz-17-pc { @include media(pc) { font-size: 17px; } }
.u-fz-18-pc { @include media(pc) { font-size: 18px; } }
.u-fz-19-pc { @include media(pc) { font-size: 19px; } }
.u-fz-20-pc { @include media(pc) { font-size: 20px; } }
.u-fz-10-sp { @include media(sp) { font-size: sp(10); } }
.u-fz-11-sp { @include media(sp) { font-size: sp(11); } }
.u-fz-12-sp { @include media(sp) { font-size: sp(12); } }
.u-fz-13-sp { @include media(sp) { font-size: sp(13); } }
.u-fz-14-sp { @include media(sp) { font-size: sp(14); } }
.u-fz-15-sp { @include media(sp) { font-size: sp(15); } }
.u-fz-16-sp { @include media(sp) { font-size: sp(16); } }
.u-fz-17-sp { @include media(sp) { font-size: sp(17); } }
.u-fz-18-sp { @include media(sp) { font-size: sp(18); } }
.u-fz-19-sp { @include media(sp) { font-size: sp(19); } }
.u-fz-20-sp { @include media(sp) { font-size: sp(20); } }


// ----------------------------------------
// Margin
// ----------------------------------------
.u-mt-0-pc   { @include media(pc) { margin-top: 0px;   } }
.u-mt-5-pc   { @include media(pc) { margin-top: 5px;   } }
.u-mt-10-pc  { @include media(pc) { margin-top: 10px;  } }
.u-mt-15-pc  { @include media(pc) { margin-top: 15px;  } }
.u-mt-20-pc  { @include media(pc) { margin-top: 20px;  } }
.u-mt-25-pc  { @include media(pc) { margin-top: 25px;  } }
.u-mt-30-pc  { @include media(pc) { margin-top: 30px;  } }
.u-mt-35-pc  { @include media(pc) { margin-top: 35px;  } }
.u-mt-40-pc  { @include media(pc) { margin-top: 40px;  } }
.u-mt-45-pc  { @include media(pc) { margin-top: 45px;  } }
.u-mt-50-pc  { @include media(pc) { margin-top: 50px;  } }
.u-mt-55-pc  { @include media(pc) { margin-top: 55px;  } }
.u-mt-60-pc  { @include media(pc) { margin-top: 60px;  } }
.u-mt-65-pc  { @include media(pc) { margin-top: 65px;  } }
.u-mt-70-pc  { @include media(pc) { margin-top: 70px;  } }
.u-mt-75-pc  { @include media(pc) { margin-top: 75px;  } }
.u-mt-80-pc  { @include media(pc) { margin-top: 80px;  } }
.u-mt-85-pc  { @include media(pc) { margin-top: 85px;  } }
.u-mt-90-pc  { @include media(pc) { margin-top: 90px;  } }
.u-mt-95-pc  { @include media(pc) { margin-top: 95px;  } }
.u-mt-100-pc { @include media(pc) { margin-top: 100px; } }
.u-mt-105-pc { @include media(pc) { margin-top: 105px; } }
.u-mt-110-pc { @include media(pc) { margin-top: 110px; } }
.u-mt-115-pc { @include media(pc) { margin-top: 115px; } }
.u-mt-120-pc { @include media(pc) { margin-top: 120px; } }
.u-mt-125-pc { @include media(pc) { margin-top: 125px; } }
.u-mt-130-pc { @include media(pc) { margin-top: 130px; } }
.u-mt-135-pc { @include media(pc) { margin-top: 135px; } }
.u-mt-140-pc { @include media(pc) { margin-top: 140px; } }
.u-mt-145-pc { @include media(pc) { margin-top: 145px; } }
.u-mt-150-pc { @include media(pc) { margin-top: 150px; } }
.u-mt-155-pc { @include media(pc) { margin-top: 155px; } }
.u-mt-160-pc { @include media(pc) { margin-top: 160px; } }
.u-mt-165-pc { @include media(pc) { margin-top: 165px; } }
.u-mt-170-pc { @include media(pc) { margin-top: 170px; } }
.u-mt-175-pc { @include media(pc) { margin-top: 175px; } }
.u-mt-180-pc { @include media(pc) { margin-top: 180px; } }
.u-mt-185-pc { @include media(pc) { margin-top: 185px; } }
.u-mt-190-pc { @include media(pc) { margin-top: 190px; } }
.u-mt-195-pc { @include media(pc) { margin-top: 195px; } }
.u-mt-200-pc { @include media(pc) { margin-top: 200px; } }

.u-mr-0-pc   { @include media(pc) { margin-right: 0px;   } }
.u-mr-5-pc   { @include media(pc) { margin-right: 5px;   } }
.u-mr-10-pc  { @include media(pc) { margin-right: 10px;  } }
.u-mr-15-pc  { @include media(pc) { margin-right: 15px;  } }
.u-mr-20-pc  { @include media(pc) { margin-right: 20px;  } }
.u-mr-25-pc  { @include media(pc) { margin-right: 25px;  } }
.u-mr-30-pc  { @include media(pc) { margin-right: 30px;  } }
.u-mr-35-pc  { @include media(pc) { margin-right: 35px;  } }
.u-mr-40-pc  { @include media(pc) { margin-right: 40px;  } }
.u-mr-45-pc  { @include media(pc) { margin-right: 45px;  } }
.u-mr-50-pc  { @include media(pc) { margin-right: 50px;  } }
.u-mr-55-pc  { @include media(pc) { margin-right: 55px;  } }
.u-mr-60-pc  { @include media(pc) { margin-right: 60px;  } }
.u-mr-65-pc  { @include media(pc) { margin-right: 65px;  } }
.u-mr-70-pc  { @include media(pc) { margin-right: 70px;  } }
.u-mr-75-pc  { @include media(pc) { margin-right: 75px;  } }
.u-mr-80-pc  { @include media(pc) { margin-right: 80px;  } }
.u-mr-85-pc  { @include media(pc) { margin-right: 85px;  } }
.u-mr-90-pc  { @include media(pc) { margin-right: 90px;  } }
.u-mr-95-pc  { @include media(pc) { margin-right: 95px;  } }
.u-mr-100-pc { @include media(pc) { margin-right: 100px; } }
.u-mr-105-pc { @include media(pc) { margin-right: 105px; } }
.u-mr-110-pc { @include media(pc) { margin-right: 110px; } }
.u-mr-115-pc { @include media(pc) { margin-right: 115px; } }
.u-mr-120-pc { @include media(pc) { margin-right: 120px; } }
.u-mr-125-pc { @include media(pc) { margin-right: 125px; } }
.u-mr-130-pc { @include media(pc) { margin-right: 130px; } }
.u-mr-135-pc { @include media(pc) { margin-right: 135px; } }
.u-mr-140-pc { @include media(pc) { margin-right: 140px; } }
.u-mr-145-pc { @include media(pc) { margin-right: 145px; } }
.u-mr-150-pc { @include media(pc) { margin-right: 150px; } }
.u-mr-155-pc { @include media(pc) { margin-right: 155px; } }
.u-mr-160-pc { @include media(pc) { margin-right: 160px; } }
.u-mr-165-pc { @include media(pc) { margin-right: 165px; } }
.u-mr-170-pc { @include media(pc) { margin-right: 170px; } }
.u-mr-175-pc { @include media(pc) { margin-right: 175px; } }
.u-mr-180-pc { @include media(pc) { margin-right: 180px; } }
.u-mr-185-pc { @include media(pc) { margin-right: 185px; } }
.u-mr-190-pc { @include media(pc) { margin-right: 190px; } }
.u-mr-195-pc { @include media(pc) { margin-right: 195px; } }
.u-mr-200-pc { @include media(pc) { margin-right: 200px; } }

.u-mb-0-pc   { @include media(pc) { margin-bottom: 0px;   } }
.u-mb-5-pc   { @include media(pc) { margin-bottom: 5px;   } }
.u-mb-10-pc  { @include media(pc) { margin-bottom: 10px;  } }
.u-mb-15-pc  { @include media(pc) { margin-bottom: 15px;  } }
.u-mb-20-pc  { @include media(pc) { margin-bottom: 20px;  } }
.u-mb-25-pc  { @include media(pc) { margin-bottom: 25px;  } }
.u-mb-30-pc  { @include media(pc) { margin-bottom: 30px;  } }
.u-mb-35-pc  { @include media(pc) { margin-bottom: 35px;  } }
.u-mb-40-pc  { @include media(pc) { margin-bottom: 40px;  } }
.u-mb-45-pc  { @include media(pc) { margin-bottom: 45px;  } }
.u-mb-50-pc  { @include media(pc) { margin-bottom: 50px;  } }
.u-mb-55-pc  { @include media(pc) { margin-bottom: 55px;  } }
.u-mb-60-pc  { @include media(pc) { margin-bottom: 60px;  } }
.u-mb-65-pc  { @include media(pc) { margin-bottom: 65px;  } }
.u-mb-70-pc  { @include media(pc) { margin-bottom: 70px;  } }
.u-mb-75-pc  { @include media(pc) { margin-bottom: 75px;  } }
.u-mb-80-pc  { @include media(pc) { margin-bottom: 80px;  } }
.u-mb-85-pc  { @include media(pc) { margin-bottom: 85px;  } }
.u-mb-90-pc  { @include media(pc) { margin-bottom: 90px;  } }
.u-mb-95-pc  { @include media(pc) { margin-bottom: 95px;  } }
.u-mb-100-pc { @include media(pc) { margin-bottom: 100px; } }
.u-mb-105-pc { @include media(pc) { margin-bottom: 105px; } }
.u-mb-110-pc { @include media(pc) { margin-bottom: 110px; } }
.u-mb-115-pc { @include media(pc) { margin-bottom: 115px; } }
.u-mb-120-pc { @include media(pc) { margin-bottom: 120px; } }
.u-mb-125-pc { @include media(pc) { margin-bottom: 125px; } }
.u-mb-130-pc { @include media(pc) { margin-bottom: 130px; } }
.u-mb-135-pc { @include media(pc) { margin-bottom: 135px; } }
.u-mb-140-pc { @include media(pc) { margin-bottom: 140px; } }
.u-mb-145-pc { @include media(pc) { margin-bottom: 145px; } }
.u-mb-150-pc { @include media(pc) { margin-bottom: 150px; } }
.u-mb-155-pc { @include media(pc) { margin-bottom: 155px; } }
.u-mb-160-pc { @include media(pc) { margin-bottom: 160px; } }
.u-mb-165-pc { @include media(pc) { margin-bottom: 165px; } }
.u-mb-170-pc { @include media(pc) { margin-bottom: 170px; } }
.u-mb-175-pc { @include media(pc) { margin-bottom: 175px; } }
.u-mb-180-pc { @include media(pc) { margin-bottom: 180px; } }
.u-mb-185-pc { @include media(pc) { margin-bottom: 185px; } }
.u-mb-190-pc { @include media(pc) { margin-bottom: 190px; } }
.u-mb-195-pc { @include media(pc) { margin-bottom: 195px; } }
.u-mb-200-pc { @include media(pc) { margin-bottom: 200px; } }

.u-ml-0-pc   { @include media(pc) { margin-left: 0px;   } }
.u-ml-5-pc   { @include media(pc) { margin-left: 5px;   } }
.u-ml-10-pc  { @include media(pc) { margin-left: 10px;  } }
.u-ml-15-pc  { @include media(pc) { margin-left: 15px;  } }
.u-ml-20-pc  { @include media(pc) { margin-left: 20px;  } }
.u-ml-25-pc  { @include media(pc) { margin-left: 25px;  } }
.u-ml-30-pc  { @include media(pc) { margin-left: 30px;  } }
.u-ml-35-pc  { @include media(pc) { margin-left: 35px;  } }
.u-ml-40-pc  { @include media(pc) { margin-left: 40px;  } }
.u-ml-45-pc  { @include media(pc) { margin-left: 45px;  } }
.u-ml-50-pc  { @include media(pc) { margin-left: 50px;  } }
.u-ml-55-pc  { @include media(pc) { margin-left: 55px;  } }
.u-ml-60-pc  { @include media(pc) { margin-left: 60px;  } }
.u-ml-65-pc  { @include media(pc) { margin-left: 65px;  } }
.u-ml-70-pc  { @include media(pc) { margin-left: 70px;  } }
.u-ml-75-pc  { @include media(pc) { margin-left: 75px;  } }
.u-ml-80-pc  { @include media(pc) { margin-left: 80px;  } }
.u-ml-85-pc  { @include media(pc) { margin-left: 85px;  } }
.u-ml-90-pc  { @include media(pc) { margin-left: 90px;  } }
.u-ml-95-pc  { @include media(pc) { margin-left: 95px;  } }
.u-ml-100-pc { @include media(pc) { margin-left: 100px; } }
.u-ml-105-pc { @include media(pc) { margin-left: 105px; } }
.u-ml-110-pc { @include media(pc) { margin-left: 110px; } }
.u-ml-115-pc { @include media(pc) { margin-left: 115px; } }
.u-ml-120-pc { @include media(pc) { margin-left: 120px; } }
.u-ml-125-pc { @include media(pc) { margin-left: 125px; } }
.u-ml-130-pc { @include media(pc) { margin-left: 130px; } }
.u-ml-135-pc { @include media(pc) { margin-left: 135px; } }
.u-ml-140-pc { @include media(pc) { margin-left: 140px; } }
.u-ml-145-pc { @include media(pc) { margin-left: 145px; } }
.u-ml-150-pc { @include media(pc) { margin-left: 150px; } }
.u-ml-155-pc { @include media(pc) { margin-left: 155px; } }
.u-ml-160-pc { @include media(pc) { margin-left: 160px; } }
.u-ml-165-pc { @include media(pc) { margin-left: 165px; } }
.u-ml-170-pc { @include media(pc) { margin-left: 170px; } }
.u-ml-175-pc { @include media(pc) { margin-left: 175px; } }
.u-ml-180-pc { @include media(pc) { margin-left: 180px; } }
.u-ml-185-pc { @include media(pc) { margin-left: 185px; } }
.u-ml-190-pc { @include media(pc) { margin-left: 190px; } }
.u-ml-195-pc { @include media(pc) { margin-left: 195px; } }
.u-ml-200-pc { @include media(pc) { margin-left: 200px; } }

.u-mt-0-sp   { @include media(sp) { margin-top: sp(0);   } }
.u-mt-5-sp   { @include media(sp) { margin-top: sp(5);   } }
.u-mt-10-sp  { @include media(sp) { margin-top: sp(10);  } }
.u-mt-15-sp  { @include media(sp) { margin-top: sp(15);  } }
.u-mt-20-sp  { @include media(sp) { margin-top: sp(20);  } }
.u-mt-25-sp  { @include media(sp) { margin-top: sp(25);  } }
.u-mt-30-sp  { @include media(sp) { margin-top: sp(30);  } }
.u-mt-35-sp  { @include media(sp) { margin-top: sp(35);  } }
.u-mt-40-sp  { @include media(sp) { margin-top: sp(40);  } }
.u-mt-45-sp  { @include media(sp) { margin-top: sp(45);  } }
.u-mt-50-sp  { @include media(sp) { margin-top: sp(50);  } }
.u-mt-55-sp  { @include media(sp) { margin-top: sp(55);  } }
.u-mt-60-sp  { @include media(sp) { margin-top: sp(60);  } }
.u-mt-65-sp  { @include media(sp) { margin-top: sp(65);  } }
.u-mt-70-sp  { @include media(sp) { margin-top: sp(70);  } }
.u-mt-75-sp  { @include media(sp) { margin-top: sp(75);  } }
.u-mt-80-sp  { @include media(sp) { margin-top: sp(80);  } }
.u-mt-85-sp  { @include media(sp) { margin-top: sp(85);  } }
.u-mt-90-sp  { @include media(sp) { margin-top: sp(90);  } }
.u-mt-95-sp  { @include media(sp) { margin-top: sp(95);  } }
.u-mt-100-sp { @include media(sp) { margin-top: sp(100); } }
.u-mt-105-sp { @include media(sp) { margin-top: sp(105); } }
.u-mt-110-sp { @include media(sp) { margin-top: sp(110); } }
.u-mt-115-sp { @include media(sp) { margin-top: sp(115); } }
.u-mt-120-sp { @include media(sp) { margin-top: sp(120); } }
.u-mt-125-sp { @include media(sp) { margin-top: sp(125); } }
.u-mt-130-sp { @include media(sp) { margin-top: sp(130); } }
.u-mt-135-sp { @include media(sp) { margin-top: sp(135); } }
.u-mt-140-sp { @include media(sp) { margin-top: sp(140); } }
.u-mt-145-sp { @include media(sp) { margin-top: sp(145); } }
.u-mt-150-sp { @include media(sp) { margin-top: sp(150); } }
.u-mt-155-sp { @include media(sp) { margin-top: sp(155); } }
.u-mt-160-sp { @include media(sp) { margin-top: sp(160); } }
.u-mt-165-sp { @include media(sp) { margin-top: sp(165); } }
.u-mt-170-sp { @include media(sp) { margin-top: sp(170); } }
.u-mt-175-sp { @include media(sp) { margin-top: sp(175); } }
.u-mt-180-sp { @include media(sp) { margin-top: sp(180); } }
.u-mt-185-sp { @include media(sp) { margin-top: sp(185); } }
.u-mt-190-sp { @include media(sp) { margin-top: sp(190); } }
.u-mt-195-sp { @include media(sp) { margin-top: sp(195); } }
.u-mt-200-sp { @include media(sp) { margin-top: sp(200); } }

.u-mr-0-sp   { @include media(sp) { margin-right: sp(0);   } }
.u-mr-5-sp   { @include media(sp) { margin-right: sp(5);   } }
.u-mr-10-sp  { @include media(sp) { margin-right: sp(10);  } }
.u-mr-15-sp  { @include media(sp) { margin-right: sp(15);  } }
.u-mr-20-sp  { @include media(sp) { margin-right: sp(20);  } }
.u-mr-25-sp  { @include media(sp) { margin-right: sp(25);  } }
.u-mr-30-sp  { @include media(sp) { margin-right: sp(30);  } }
.u-mr-35-sp  { @include media(sp) { margin-right: sp(35);  } }
.u-mr-40-sp  { @include media(sp) { margin-right: sp(40);  } }
.u-mr-45-sp  { @include media(sp) { margin-right: sp(45);  } }
.u-mr-50-sp  { @include media(sp) { margin-right: sp(50);  } }
.u-mr-55-sp  { @include media(sp) { margin-right: sp(55);  } }
.u-mr-60-sp  { @include media(sp) { margin-right: sp(60);  } }
.u-mr-65-sp  { @include media(sp) { margin-right: sp(65);  } }
.u-mr-70-sp  { @include media(sp) { margin-right: sp(70);  } }
.u-mr-75-sp  { @include media(sp) { margin-right: sp(75);  } }
.u-mr-80-sp  { @include media(sp) { margin-right: sp(80);  } }
.u-mr-85-sp  { @include media(sp) { margin-right: sp(85);  } }
.u-mr-90-sp  { @include media(sp) { margin-right: sp(90);  } }
.u-mr-95-sp  { @include media(sp) { margin-right: sp(95);  } }
.u-mr-100-sp { @include media(sp) { margin-right: sp(100); } }
.u-mr-105-sp { @include media(sp) { margin-right: sp(105); } }
.u-mr-110-sp { @include media(sp) { margin-right: sp(110); } }
.u-mr-115-sp { @include media(sp) { margin-right: sp(115); } }
.u-mr-120-sp { @include media(sp) { margin-right: sp(120); } }
.u-mr-125-sp { @include media(sp) { margin-right: sp(125); } }
.u-mr-130-sp { @include media(sp) { margin-right: sp(130); } }
.u-mr-135-sp { @include media(sp) { margin-right: sp(135); } }
.u-mr-140-sp { @include media(sp) { margin-right: sp(140); } }
.u-mr-145-sp { @include media(sp) { margin-right: sp(145); } }
.u-mr-150-sp { @include media(sp) { margin-right: sp(150); } }
.u-mr-155-sp { @include media(sp) { margin-right: sp(155); } }
.u-mr-160-sp { @include media(sp) { margin-right: sp(160); } }
.u-mr-165-sp { @include media(sp) { margin-right: sp(165); } }
.u-mr-170-sp { @include media(sp) { margin-right: sp(170); } }
.u-mr-175-sp { @include media(sp) { margin-right: sp(175); } }
.u-mr-180-sp { @include media(sp) { margin-right: sp(180); } }
.u-mr-185-sp { @include media(sp) { margin-right: sp(185); } }
.u-mr-190-sp { @include media(sp) { margin-right: sp(190); } }
.u-mr-195-sp { @include media(sp) { margin-right: sp(195); } }
.u-mr-200-sp { @include media(sp) { margin-right: sp(200); } }

.u-mb-0-sp   { @include media(sp) { margin-bottom: sp(0);   } }
.u-mb-5-sp   { @include media(sp) { margin-bottom: sp(5);   } }
.u-mb-10-sp  { @include media(sp) { margin-bottom: sp(10);  } }
.u-mb-15-sp  { @include media(sp) { margin-bottom: sp(15);  } }
.u-mb-20-sp  { @include media(sp) { margin-bottom: sp(20);  } }
.u-mb-25-sp  { @include media(sp) { margin-bottom: sp(25);  } }
.u-mb-30-sp  { @include media(sp) { margin-bottom: sp(30);  } }
.u-mb-35-sp  { @include media(sp) { margin-bottom: sp(35);  } }
.u-mb-40-sp  { @include media(sp) { margin-bottom: sp(40);  } }
.u-mb-45-sp  { @include media(sp) { margin-bottom: sp(45);  } }
.u-mb-50-sp  { @include media(sp) { margin-bottom: sp(50);  } }
.u-mb-55-sp  { @include media(sp) { margin-bottom: sp(55);  } }
.u-mb-60-sp  { @include media(sp) { margin-bottom: sp(60);  } }
.u-mb-65-sp  { @include media(sp) { margin-bottom: sp(65);  } }
.u-mb-70-sp  { @include media(sp) { margin-bottom: sp(70);  } }
.u-mb-75-sp  { @include media(sp) { margin-bottom: sp(75);  } }
.u-mb-80-sp  { @include media(sp) { margin-bottom: sp(80);  } }
.u-mb-85-sp  { @include media(sp) { margin-bottom: sp(85);  } }
.u-mb-90-sp  { @include media(sp) { margin-bottom: sp(90);  } }
.u-mb-95-sp  { @include media(sp) { margin-bottom: sp(95);  } }
.u-mb-100-sp { @include media(sp) { margin-bottom: sp(100); } }
.u-mb-105-sp { @include media(sp) { margin-bottom: sp(105); } }
.u-mb-110-sp { @include media(sp) { margin-bottom: sp(110); } }
.u-mb-115-sp { @include media(sp) { margin-bottom: sp(115); } }
.u-mb-120-sp { @include media(sp) { margin-bottom: sp(120); } }
.u-mb-125-sp { @include media(sp) { margin-bottom: sp(125); } }
.u-mb-130-sp { @include media(sp) { margin-bottom: sp(130); } }
.u-mb-135-sp { @include media(sp) { margin-bottom: sp(135); } }
.u-mb-140-sp { @include media(sp) { margin-bottom: sp(140); } }
.u-mb-145-sp { @include media(sp) { margin-bottom: sp(145); } }
.u-mb-150-sp { @include media(sp) { margin-bottom: sp(150); } }
.u-mb-155-sp { @include media(sp) { margin-bottom: sp(155); } }
.u-mb-160-sp { @include media(sp) { margin-bottom: sp(160); } }
.u-mb-165-sp { @include media(sp) { margin-bottom: sp(165); } }
.u-mb-170-sp { @include media(sp) { margin-bottom: sp(170); } }
.u-mb-175-sp { @include media(sp) { margin-bottom: sp(175); } }
.u-mb-180-sp { @include media(sp) { margin-bottom: sp(180); } }
.u-mb-185-sp { @include media(sp) { margin-bottom: sp(185); } }
.u-mb-190-sp { @include media(sp) { margin-bottom: sp(190); } }
.u-mb-195-sp { @include media(sp) { margin-bottom: sp(195); } }
.u-mb-200-sp { @include media(sp) { margin-bottom: sp(200); } }

.u-ml-0-sp   { @include media(sp) { margin-left: sp(0);   } }
.u-ml-5-sp   { @include media(sp) { margin-left: sp(5);   } }
.u-ml-10-sp  { @include media(sp) { margin-left: sp(10);  } }
.u-ml-15-sp  { @include media(sp) { margin-left: sp(15);  } }
.u-ml-20-sp  { @include media(sp) { margin-left: sp(20);  } }
.u-ml-25-sp  { @include media(sp) { margin-left: sp(25);  } }
.u-ml-30-sp  { @include media(sp) { margin-left: sp(30);  } }
.u-ml-35-sp  { @include media(sp) { margin-left: sp(35);  } }
.u-ml-40-sp  { @include media(sp) { margin-left: sp(40);  } }
.u-ml-45-sp  { @include media(sp) { margin-left: sp(45);  } }
.u-ml-50-sp  { @include media(sp) { margin-left: sp(50);  } }
.u-ml-55-sp  { @include media(sp) { margin-left: sp(55);  } }
.u-ml-60-sp  { @include media(sp) { margin-left: sp(60);  } }
.u-ml-65-sp  { @include media(sp) { margin-left: sp(65);  } }
.u-ml-70-sp  { @include media(sp) { margin-left: sp(70);  } }
.u-ml-75-sp  { @include media(sp) { margin-left: sp(75);  } }
.u-ml-80-sp  { @include media(sp) { margin-left: sp(80);  } }
.u-ml-85-sp  { @include media(sp) { margin-left: sp(85);  } }
.u-ml-90-sp  { @include media(sp) { margin-left: sp(90);  } }
.u-ml-95-sp  { @include media(sp) { margin-left: sp(95);  } }
.u-ml-100-sp { @include media(sp) { margin-left: sp(100); } }
.u-ml-105-sp { @include media(sp) { margin-left: sp(105); } }
.u-ml-110-sp { @include media(sp) { margin-left: sp(110); } }
.u-ml-115-sp { @include media(sp) { margin-left: sp(115); } }
.u-ml-120-sp { @include media(sp) { margin-left: sp(120); } }
.u-ml-125-sp { @include media(sp) { margin-left: sp(125); } }
.u-ml-130-sp { @include media(sp) { margin-left: sp(130); } }
.u-ml-135-sp { @include media(sp) { margin-left: sp(135); } }
.u-ml-140-sp { @include media(sp) { margin-left: sp(140); } }
.u-ml-145-sp { @include media(sp) { margin-left: sp(145); } }
.u-ml-150-sp { @include media(sp) { margin-left: sp(150); } }
.u-ml-155-sp { @include media(sp) { margin-left: sp(155); } }
.u-ml-160-sp { @include media(sp) { margin-left: sp(160); } }
.u-ml-165-sp { @include media(sp) { margin-left: sp(165); } }
.u-ml-170-sp { @include media(sp) { margin-left: sp(170); } }
.u-ml-175-sp { @include media(sp) { margin-left: sp(175); } }
.u-ml-180-sp { @include media(sp) { margin-left: sp(180); } }
.u-ml-185-sp { @include media(sp) { margin-left: sp(185); } }
.u-ml-190-sp { @include media(sp) { margin-left: sp(190); } }
.u-ml-195-sp { @include media(sp) { margin-left: sp(195); } }
.u-ml-200-sp { @include media(sp) { margin-left: sp(200); } }

// ----------------------------------------
// Padding
// ----------------------------------------
.u-pt-0-pc   { @include media(pc) { padding-top: 0px;   } }
.u-pt-5-pc   { @include media(pc) { padding-top: 5px;   } }
.u-pt-10-pc  { @include media(pc) { padding-top: 10px;  } }
.u-pt-15-pc  { @include media(pc) { padding-top: 15px;  } }
.u-pt-20-pc  { @include media(pc) { padding-top: 20px;  } }
.u-pt-25-pc  { @include media(pc) { padding-top: 25px;  } }
.u-pt-30-pc  { @include media(pc) { padding-top: 30px;  } }
.u-pt-35-pc  { @include media(pc) { padding-top: 35px;  } }
.u-pt-40-pc  { @include media(pc) { padding-top: 40px;  } }
.u-pt-45-pc  { @include media(pc) { padding-top: 45px;  } }
.u-pt-50-pc  { @include media(pc) { padding-top: 50px;  } }
.u-pt-55-pc  { @include media(pc) { padding-top: 55px;  } }
.u-pt-60-pc  { @include media(pc) { padding-top: 60px;  } }
.u-pt-65-pc  { @include media(pc) { padding-top: 65px;  } }
.u-pt-70-pc  { @include media(pc) { padding-top: 70px;  } }
.u-pt-75-pc  { @include media(pc) { padding-top: 75px;  } }
.u-pt-80-pc  { @include media(pc) { padding-top: 80px;  } }
.u-pt-85-pc  { @include media(pc) { padding-top: 85px;  } }
.u-pt-90-pc  { @include media(pc) { padding-top: 90px;  } }
.u-pt-95-pc  { @include media(pc) { padding-top: 95px;  } }
.u-pt-100-pc { @include media(pc) { padding-top: 100px; } }
.u-pt-105-pc { @include media(pc) { padding-top: 105px; } }
.u-pt-110-pc { @include media(pc) { padding-top: 110px; } }
.u-pt-115-pc { @include media(pc) { padding-top: 115px; } }
.u-pt-120-pc { @include media(pc) { padding-top: 120px; } }
.u-pt-125-pc { @include media(pc) { padding-top: 125px; } }
.u-pt-130-pc { @include media(pc) { padding-top: 130px; } }
.u-pt-135-pc { @include media(pc) { padding-top: 135px; } }
.u-pt-140-pc { @include media(pc) { padding-top: 140px; } }
.u-pt-145-pc { @include media(pc) { padding-top: 145px; } }
.u-pt-150-pc { @include media(pc) { padding-top: 150px; } }
.u-pt-155-pc { @include media(pc) { padding-top: 155px; } }
.u-pt-160-pc { @include media(pc) { padding-top: 160px; } }
.u-pt-165-pc { @include media(pc) { padding-top: 165px; } }
.u-pt-170-pc { @include media(pc) { padding-top: 170px; } }
.u-pt-175-pc { @include media(pc) { padding-top: 175px; } }
.u-pt-180-pc { @include media(pc) { padding-top: 180px; } }
.u-pt-185-pc { @include media(pc) { padding-top: 185px; } }
.u-pt-190-pc { @include media(pc) { padding-top: 190px; } }
.u-pt-195-pc { @include media(pc) { padding-top: 195px; } }
.u-pt-200-pc { @include media(pc) { padding-top: 200px; } }

.u-pr-0-pc   { @include media(pc) { padding-right: 0px;   } }
.u-pr-5-pc   { @include media(pc) { padding-right: 5px;   } }
.u-pr-10-pc  { @include media(pc) { padding-right: 10px;  } }
.u-pr-15-pc  { @include media(pc) { padding-right: 15px;  } }
.u-pr-20-pc  { @include media(pc) { padding-right: 20px;  } }
.u-pr-25-pc  { @include media(pc) { padding-right: 25px;  } }
.u-pr-30-pc  { @include media(pc) { padding-right: 30px;  } }
.u-pr-35-pc  { @include media(pc) { padding-right: 35px;  } }
.u-pr-40-pc  { @include media(pc) { padding-right: 40px;  } }
.u-pr-45-pc  { @include media(pc) { padding-right: 45px;  } }
.u-pr-50-pc  { @include media(pc) { padding-right: 50px;  } }
.u-pr-55-pc  { @include media(pc) { padding-right: 55px;  } }
.u-pr-60-pc  { @include media(pc) { padding-right: 60px;  } }
.u-pr-65-pc  { @include media(pc) { padding-right: 65px;  } }
.u-pr-70-pc  { @include media(pc) { padding-right: 70px;  } }
.u-pr-75-pc  { @include media(pc) { padding-right: 75px;  } }
.u-pr-80-pc  { @include media(pc) { padding-right: 80px;  } }
.u-pr-85-pc  { @include media(pc) { padding-right: 85px;  } }
.u-pr-90-pc  { @include media(pc) { padding-right: 90px;  } }
.u-pr-95-pc  { @include media(pc) { padding-right: 95px;  } }
.u-pr-100-pc { @include media(pc) { padding-right: 100px; } }
.u-pr-105-pc { @include media(pc) { padding-right: 105px; } }
.u-pr-110-pc { @include media(pc) { padding-right: 110px; } }
.u-pr-115-pc { @include media(pc) { padding-right: 115px; } }
.u-pr-120-pc { @include media(pc) { padding-right: 120px; } }
.u-pr-125-pc { @include media(pc) { padding-right: 125px; } }
.u-pr-130-pc { @include media(pc) { padding-right: 130px; } }
.u-pr-135-pc { @include media(pc) { padding-right: 135px; } }
.u-pr-140-pc { @include media(pc) { padding-right: 140px; } }
.u-pr-145-pc { @include media(pc) { padding-right: 145px; } }
.u-pr-150-pc { @include media(pc) { padding-right: 150px; } }
.u-pr-155-pc { @include media(pc) { padding-right: 155px; } }
.u-pr-160-pc { @include media(pc) { padding-right: 160px; } }
.u-pr-165-pc { @include media(pc) { padding-right: 165px; } }
.u-pr-170-pc { @include media(pc) { padding-right: 170px; } }
.u-pr-175-pc { @include media(pc) { padding-right: 175px; } }
.u-pr-180-pc { @include media(pc) { padding-right: 180px; } }
.u-pr-185-pc { @include media(pc) { padding-right: 185px; } }
.u-pr-190-pc { @include media(pc) { padding-right: 190px; } }
.u-pr-195-pc { @include media(pc) { padding-right: 195px; } }
.u-pr-200-pc { @include media(pc) { padding-right: 200px; } }

.u-pb-0-pc   { @include media(pc) { padding-bottom: 0px;   } }
.u-pb-5-pc   { @include media(pc) { padding-bottom: 5px;   } }
.u-pb-10-pc  { @include media(pc) { padding-bottom: 10px;  } }
.u-pb-15-pc  { @include media(pc) { padding-bottom: 15px;  } }
.u-pb-20-pc  { @include media(pc) { padding-bottom: 20px;  } }
.u-pb-25-pc  { @include media(pc) { padding-bottom: 25px;  } }
.u-pb-30-pc  { @include media(pc) { padding-bottom: 30px;  } }
.u-pb-35-pc  { @include media(pc) { padding-bottom: 35px;  } }
.u-pb-40-pc  { @include media(pc) { padding-bottom: 40px;  } }
.u-pb-45-pc  { @include media(pc) { padding-bottom: 45px;  } }
.u-pb-50-pc  { @include media(pc) { padding-bottom: 50px;  } }
.u-pb-55-pc  { @include media(pc) { padding-bottom: 55px;  } }
.u-pb-60-pc  { @include media(pc) { padding-bottom: 60px;  } }
.u-pb-65-pc  { @include media(pc) { padding-bottom: 65px;  } }
.u-pb-70-pc  { @include media(pc) { padding-bottom: 70px;  } }
.u-pb-75-pc  { @include media(pc) { padding-bottom: 75px;  } }
.u-pb-80-pc  { @include media(pc) { padding-bottom: 80px;  } }
.u-pb-85-pc  { @include media(pc) { padding-bottom: 85px;  } }
.u-pb-90-pc  { @include media(pc) { padding-bottom: 90px;  } }
.u-pb-95-pc  { @include media(pc) { padding-bottom: 95px;  } }
.u-pb-100-pc { @include media(pc) { padding-bottom: 100px; } }
.u-pb-105-pc { @include media(pc) { padding-bottom: 105px; } }
.u-pb-110-pc { @include media(pc) { padding-bottom: 110px; } }
.u-pb-115-pc { @include media(pc) { padding-bottom: 115px; } }
.u-pb-120-pc { @include media(pc) { padding-bottom: 120px; } }
.u-pb-125-pc { @include media(pc) { padding-bottom: 125px; } }
.u-pb-130-pc { @include media(pc) { padding-bottom: 130px; } }
.u-pb-135-pc { @include media(pc) { padding-bottom: 135px; } }
.u-pb-140-pc { @include media(pc) { padding-bottom: 140px; } }
.u-pb-145-pc { @include media(pc) { padding-bottom: 145px; } }
.u-pb-150-pc { @include media(pc) { padding-bottom: 150px; } }
.u-pb-155-pc { @include media(pc) { padding-bottom: 155px; } }
.u-pb-160-pc { @include media(pc) { padding-bottom: 160px; } }
.u-pb-165-pc { @include media(pc) { padding-bottom: 165px; } }
.u-pb-170-pc { @include media(pc) { padding-bottom: 170px; } }
.u-pb-175-pc { @include media(pc) { padding-bottom: 175px; } }
.u-pb-180-pc { @include media(pc) { padding-bottom: 180px; } }
.u-pb-185-pc { @include media(pc) { padding-bottom: 185px; } }
.u-pb-190-pc { @include media(pc) { padding-bottom: 190px; } }
.u-pb-195-pc { @include media(pc) { padding-bottom: 195px; } }
.u-pb-200-pc { @include media(pc) { padding-bottom: 200px; } }

.u-pl-0-pc   { @include media(pc) { padding-left: 0px;   } }
.u-pl-5-pc   { @include media(pc) { padding-left: 5px;   } }
.u-pl-10-pc  { @include media(pc) { padding-left: 10px;  } }
.u-pl-15-pc  { @include media(pc) { padding-left: 15px;  } }
.u-pl-20-pc  { @include media(pc) { padding-left: 20px;  } }
.u-pl-25-pc  { @include media(pc) { padding-left: 25px;  } }
.u-pl-30-pc  { @include media(pc) { padding-left: 30px;  } }
.u-pl-35-pc  { @include media(pc) { padding-left: 35px;  } }
.u-pl-40-pc  { @include media(pc) { padding-left: 40px;  } }
.u-pl-45-pc  { @include media(pc) { padding-left: 45px;  } }
.u-pl-50-pc  { @include media(pc) { padding-left: 50px;  } }
.u-pl-55-pc  { @include media(pc) { padding-left: 55px;  } }
.u-pl-60-pc  { @include media(pc) { padding-left: 60px;  } }
.u-pl-65-pc  { @include media(pc) { padding-left: 65px;  } }
.u-pl-70-pc  { @include media(pc) { padding-left: 70px;  } }
.u-pl-75-pc  { @include media(pc) { padding-left: 75px;  } }
.u-pl-80-pc  { @include media(pc) { padding-left: 80px;  } }
.u-pl-85-pc  { @include media(pc) { padding-left: 85px;  } }
.u-pl-90-pc  { @include media(pc) { padding-left: 90px;  } }
.u-pl-95-pc  { @include media(pc) { padding-left: 95px;  } }
.u-pl-100-pc { @include media(pc) { padding-left: 100px; } }
.u-pl-105-pc { @include media(pc) { padding-left: 105px; } }
.u-pl-110-pc { @include media(pc) { padding-left: 110px; } }
.u-pl-115-pc { @include media(pc) { padding-left: 115px; } }
.u-pl-120-pc { @include media(pc) { padding-left: 120px; } }
.u-pl-125-pc { @include media(pc) { padding-left: 125px; } }
.u-pl-130-pc { @include media(pc) { padding-left: 130px; } }
.u-pl-135-pc { @include media(pc) { padding-left: 135px; } }
.u-pl-140-pc { @include media(pc) { padding-left: 140px; } }
.u-pl-145-pc { @include media(pc) { padding-left: 145px; } }
.u-pl-150-pc { @include media(pc) { padding-left: 150px; } }
.u-pl-155-pc { @include media(pc) { padding-left: 155px; } }
.u-pl-160-pc { @include media(pc) { padding-left: 160px; } }
.u-pl-165-pc { @include media(pc) { padding-left: 165px; } }
.u-pl-170-pc { @include media(pc) { padding-left: 170px; } }
.u-pl-175-pc { @include media(pc) { padding-left: 175px; } }
.u-pl-180-pc { @include media(pc) { padding-left: 180px; } }
.u-pl-185-pc { @include media(pc) { padding-left: 185px; } }
.u-pl-190-pc { @include media(pc) { padding-left: 190px; } }
.u-pl-195-pc { @include media(pc) { padding-left: 195px; } }
.u-pl-200-pc { @include media(pc) { padding-left: 200px; } }

.u-pt-0-sp   { @include media(sp) { padding-top: sp(0);   } }
.u-pt-5-sp   { @include media(sp) { padding-top: sp(5);   } }
.u-pt-10-sp  { @include media(sp) { padding-top: sp(10);  } }
.u-pt-15-sp  { @include media(sp) { padding-top: sp(15);  } }
.u-pt-20-sp  { @include media(sp) { padding-top: sp(20);  } }
.u-pt-25-sp  { @include media(sp) { padding-top: sp(25);  } }
.u-pt-30-sp  { @include media(sp) { padding-top: sp(30);  } }
.u-pt-35-sp  { @include media(sp) { padding-top: sp(35);  } }
.u-pt-40-sp  { @include media(sp) { padding-top: sp(40);  } }
.u-pt-45-sp  { @include media(sp) { padding-top: sp(45);  } }
.u-pt-50-sp  { @include media(sp) { padding-top: sp(50);  } }
.u-pt-55-sp  { @include media(sp) { padding-top: sp(55);  } }
.u-pt-60-sp  { @include media(sp) { padding-top: sp(60);  } }
.u-pt-65-sp  { @include media(sp) { padding-top: sp(65);  } }
.u-pt-70-sp  { @include media(sp) { padding-top: sp(70);  } }
.u-pt-75-sp  { @include media(sp) { padding-top: sp(75);  } }
.u-pt-80-sp  { @include media(sp) { padding-top: sp(80);  } }
.u-pt-85-sp  { @include media(sp) { padding-top: sp(85);  } }
.u-pt-90-sp  { @include media(sp) { padding-top: sp(90);  } }
.u-pt-95-sp  { @include media(sp) { padding-top: sp(95);  } }
.u-pt-100-sp { @include media(sp) { padding-top: sp(100); } }
.u-pt-105-sp { @include media(sp) { padding-top: sp(105); } }
.u-pt-110-sp { @include media(sp) { padding-top: sp(110); } }
.u-pt-115-sp { @include media(sp) { padding-top: sp(115); } }
.u-pt-120-sp { @include media(sp) { padding-top: sp(120); } }
.u-pt-125-sp { @include media(sp) { padding-top: sp(125); } }
.u-pt-130-sp { @include media(sp) { padding-top: sp(130); } }
.u-pt-135-sp { @include media(sp) { padding-top: sp(135); } }
.u-pt-140-sp { @include media(sp) { padding-top: sp(140); } }
.u-pt-145-sp { @include media(sp) { padding-top: sp(145); } }
.u-pt-150-sp { @include media(sp) { padding-top: sp(150); } }
.u-pt-155-sp { @include media(sp) { padding-top: sp(155); } }
.u-pt-160-sp { @include media(sp) { padding-top: sp(160); } }
.u-pt-165-sp { @include media(sp) { padding-top: sp(165); } }
.u-pt-170-sp { @include media(sp) { padding-top: sp(170); } }
.u-pt-175-sp { @include media(sp) { padding-top: sp(175); } }
.u-pt-180-sp { @include media(sp) { padding-top: sp(180); } }
.u-pt-185-sp { @include media(sp) { padding-top: sp(185); } }
.u-pt-190-sp { @include media(sp) { padding-top: sp(190); } }
.u-pt-195-sp { @include media(sp) { padding-top: sp(195); } }
.u-pt-200-sp { @include media(sp) { padding-top: sp(200); } }

.u-pr-0-sp   { @include media(sp) { padding-right: sp(0);   } }
.u-pr-5-sp   { @include media(sp) { padding-right: sp(5);   } }
.u-pr-10-sp  { @include media(sp) { padding-right: sp(10);  } }
.u-pr-15-sp  { @include media(sp) { padding-right: sp(15);  } }
.u-pr-20-sp  { @include media(sp) { padding-right: sp(20);  } }
.u-pr-25-sp  { @include media(sp) { padding-right: sp(25);  } }
.u-pr-30-sp  { @include media(sp) { padding-right: sp(30);  } }
.u-pr-35-sp  { @include media(sp) { padding-right: sp(35);  } }
.u-pr-40-sp  { @include media(sp) { padding-right: sp(40);  } }
.u-pr-45-sp  { @include media(sp) { padding-right: sp(45);  } }
.u-pr-50-sp  { @include media(sp) { padding-right: sp(50);  } }
.u-pr-55-sp  { @include media(sp) { padding-right: sp(55);  } }
.u-pr-60-sp  { @include media(sp) { padding-right: sp(60);  } }
.u-pr-65-sp  { @include media(sp) { padding-right: sp(65);  } }
.u-pr-70-sp  { @include media(sp) { padding-right: sp(70);  } }
.u-pr-75-sp  { @include media(sp) { padding-right: sp(75);  } }
.u-pr-80-sp  { @include media(sp) { padding-right: sp(80);  } }
.u-pr-85-sp  { @include media(sp) { padding-right: sp(85);  } }
.u-pr-90-sp  { @include media(sp) { padding-right: sp(90);  } }
.u-pr-95-sp  { @include media(sp) { padding-right: sp(95);  } }
.u-pr-100-sp { @include media(sp) { padding-right: sp(100); } }
.u-pr-105-sp { @include media(sp) { padding-right: sp(105); } }
.u-pr-110-sp { @include media(sp) { padding-right: sp(110); } }
.u-pr-115-sp { @include media(sp) { padding-right: sp(115); } }
.u-pr-120-sp { @include media(sp) { padding-right: sp(120); } }
.u-pr-125-sp { @include media(sp) { padding-right: sp(125); } }
.u-pr-130-sp { @include media(sp) { padding-right: sp(130); } }
.u-pr-135-sp { @include media(sp) { padding-right: sp(135); } }
.u-pr-140-sp { @include media(sp) { padding-right: sp(140); } }
.u-pr-145-sp { @include media(sp) { padding-right: sp(145); } }
.u-pr-150-sp { @include media(sp) { padding-right: sp(150); } }
.u-pr-155-sp { @include media(sp) { padding-right: sp(155); } }
.u-pr-160-sp { @include media(sp) { padding-right: sp(160); } }
.u-pr-165-sp { @include media(sp) { padding-right: sp(165); } }
.u-pr-170-sp { @include media(sp) { padding-right: sp(170); } }
.u-pr-175-sp { @include media(sp) { padding-right: sp(175); } }
.u-pr-180-sp { @include media(sp) { padding-right: sp(180); } }
.u-pr-185-sp { @include media(sp) { padding-right: sp(185); } }
.u-pr-190-sp { @include media(sp) { padding-right: sp(190); } }
.u-pr-195-sp { @include media(sp) { padding-right: sp(195); } }
.u-pr-200-sp { @include media(sp) { padding-right: sp(200); } }

.u-pb-0-sp   { @include media(sp) { padding-bottom: sp(0);   } }
.u-pb-5-sp   { @include media(sp) { padding-bottom: sp(5);   } }
.u-pb-10-sp  { @include media(sp) { padding-bottom: sp(10);  } }
.u-pb-15-sp  { @include media(sp) { padding-bottom: sp(15);  } }
.u-pb-20-sp  { @include media(sp) { padding-bottom: sp(20);  } }
.u-pb-25-sp  { @include media(sp) { padding-bottom: sp(25);  } }
.u-pb-30-sp  { @include media(sp) { padding-bottom: sp(30);  } }
.u-pb-35-sp  { @include media(sp) { padding-bottom: sp(35);  } }
.u-pb-40-sp  { @include media(sp) { padding-bottom: sp(40);  } }
.u-pb-45-sp  { @include media(sp) { padding-bottom: sp(45);  } }
.u-pb-50-sp  { @include media(sp) { padding-bottom: sp(50);  } }
.u-pb-55-sp  { @include media(sp) { padding-bottom: sp(55);  } }
.u-pb-60-sp  { @include media(sp) { padding-bottom: sp(60);  } }
.u-pb-65-sp  { @include media(sp) { padding-bottom: sp(65);  } }
.u-pb-70-sp  { @include media(sp) { padding-bottom: sp(70);  } }
.u-pb-75-sp  { @include media(sp) { padding-bottom: sp(75);  } }
.u-pb-80-sp  { @include media(sp) { padding-bottom: sp(80);  } }
.u-pb-85-sp  { @include media(sp) { padding-bottom: sp(85);  } }
.u-pb-90-sp  { @include media(sp) { padding-bottom: sp(90);  } }
.u-pb-95-sp  { @include media(sp) { padding-bottom: sp(95);  } }
.u-pb-100-sp { @include media(sp) { padding-bottom: sp(100); } }
.u-pb-105-sp { @include media(sp) { padding-bottom: sp(105); } }
.u-pb-110-sp { @include media(sp) { padding-bottom: sp(110); } }
.u-pb-115-sp { @include media(sp) { padding-bottom: sp(115); } }
.u-pb-120-sp { @include media(sp) { padding-bottom: sp(120); } }
.u-pb-125-sp { @include media(sp) { padding-bottom: sp(125); } }
.u-pb-130-sp { @include media(sp) { padding-bottom: sp(130); } }
.u-pb-135-sp { @include media(sp) { padding-bottom: sp(135); } }
.u-pb-140-sp { @include media(sp) { padding-bottom: sp(140); } }
.u-pb-145-sp { @include media(sp) { padding-bottom: sp(145); } }
.u-pb-150-sp { @include media(sp) { padding-bottom: sp(150); } }
.u-pb-155-sp { @include media(sp) { padding-bottom: sp(155); } }
.u-pb-160-sp { @include media(sp) { padding-bottom: sp(160); } }
.u-pb-165-sp { @include media(sp) { padding-bottom: sp(165); } }
.u-pb-170-sp { @include media(sp) { padding-bottom: sp(170); } }
.u-pb-175-sp { @include media(sp) { padding-bottom: sp(175); } }
.u-pb-180-sp { @include media(sp) { padding-bottom: sp(180); } }
.u-pb-185-sp { @include media(sp) { padding-bottom: sp(185); } }
.u-pb-190-sp { @include media(sp) { padding-bottom: sp(190); } }
.u-pb-195-sp { @include media(sp) { padding-bottom: sp(195); } }
.u-pb-200-sp { @include media(sp) { padding-bottom: sp(200); } }

.u-pl-0-sp   { @include media(sp) { padding-left: sp(0);   } }
.u-pl-5-sp   { @include media(sp) { padding-left: sp(5);   } }
.u-pl-10-sp  { @include media(sp) { padding-left: sp(10);  } }
.u-pl-15-sp  { @include media(sp) { padding-left: sp(15);  } }
.u-pl-20-sp  { @include media(sp) { padding-left: sp(20);  } }
.u-pl-25-sp  { @include media(sp) { padding-left: sp(25);  } }
.u-pl-30-sp  { @include media(sp) { padding-left: sp(30);  } }
.u-pl-35-sp  { @include media(sp) { padding-left: sp(35);  } }
.u-pl-40-sp  { @include media(sp) { padding-left: sp(40);  } }
.u-pl-45-sp  { @include media(sp) { padding-left: sp(45);  } }
.u-pl-50-sp  { @include media(sp) { padding-left: sp(50);  } }
.u-pl-55-sp  { @include media(sp) { padding-left: sp(55);  } }
.u-pl-60-sp  { @include media(sp) { padding-left: sp(60);  } }
.u-pl-65-sp  { @include media(sp) { padding-left: sp(65);  } }
.u-pl-70-sp  { @include media(sp) { padding-left: sp(70);  } }
.u-pl-75-sp  { @include media(sp) { padding-left: sp(75);  } }
.u-pl-80-sp  { @include media(sp) { padding-left: sp(80);  } }
.u-pl-85-sp  { @include media(sp) { padding-left: sp(85);  } }
.u-pl-90-sp  { @include media(sp) { padding-left: sp(90);  } }
.u-pl-95-sp  { @include media(sp) { padding-left: sp(95);  } }
.u-pl-100-sp { @include media(sp) { padding-left: sp(100); } }
.u-pl-105-sp { @include media(sp) { padding-left: sp(105); } }
.u-pl-110-sp { @include media(sp) { padding-left: sp(110); } }
.u-pl-115-sp { @include media(sp) { padding-left: sp(115); } }
.u-pl-120-sp { @include media(sp) { padding-left: sp(120); } }
.u-pl-125-sp { @include media(sp) { padding-left: sp(125); } }
.u-pl-130-sp { @include media(sp) { padding-left: sp(130); } }
.u-pl-135-sp { @include media(sp) { padding-left: sp(135); } }
.u-pl-140-sp { @include media(sp) { padding-left: sp(140); } }
.u-pl-145-sp { @include media(sp) { padding-left: sp(145); } }
.u-pl-150-sp { @include media(sp) { padding-left: sp(150); } }
.u-pl-155-sp { @include media(sp) { padding-left: sp(155); } }
.u-pl-160-sp { @include media(sp) { padding-left: sp(160); } }
.u-pl-165-sp { @include media(sp) { padding-left: sp(165); } }
.u-pl-170-sp { @include media(sp) { padding-left: sp(170); } }
.u-pl-175-sp { @include media(sp) { padding-left: sp(175); } }
.u-pl-180-sp { @include media(sp) { padding-left: sp(180); } }
.u-pl-185-sp { @include media(sp) { padding-left: sp(185); } }
.u-pl-190-sp { @include media(sp) { padding-left: sp(190); } }
.u-pl-195-sp { @include media(sp) { padding-left: sp(195); } }
.u-pl-200-sp { @include media(sp) { padding-left: sp(200); } }