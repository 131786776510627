// Google Fonts
// https://fonts.google.com

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");
// font-family: 'Noto Sans JP', sans-serif;

// @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;500;700&display=swap');
// font-family: 'Noto Serif JP', serif;

// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');
// font-family: 'Montserrat', sans-serif;

@font-face {
    font-family: 'LINESeedJP';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../font/line-seed-jp/LINESeedJP_OTF_Th.woff2') format('woff2');
}

@font-face {
    font-family: 'LINESeedJP';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../font/line-seed-jp/LINESeedJP_OTF_Rg.woff2') format('woff2');
}

@font-face {
    font-family: 'LINESeedJP';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../font/line-seed-jp/LINESeedJP_OTF_Bd.woff2') format('woff2');
}

@font-face {
    font-family: 'LINESeedJP';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../font/line-seed-jp/LINESeedJP_OTF_Eb.woff2') format('woff2');
}