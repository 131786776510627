@use "global" as *;

.index {
  @include media(pc) {

  }

  @include media(sp) {

  }
}


.index-slider {
  // background-color: #00B163;
  background-color: #fff;
  position: relative;
  @include media(pc) {
  
  }
  
  @include media(sp) {
  }

  

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 50%;
    background-color: #fff;
    @include media(pc) {
      box-shadow: 0 6px 10px rgba(0,0,0,0.16);
      border-radius: 50%;
      height: 660px;
      width: 120%;
      transform: translate(-50%, 38%);
  }
    @include media(1080px) {
      height: 500px;
      width: 140%;
    }
    
    @include media(sp) {
      box-shadow: 0 sp(6) sp(10) rgba(0,0,0,0.16);
      height: sp(800);
      border-radius: 50%;
      width: 220%;
      transform: translate(-50%, 40%);
    }
  }
}

.index-slider_container {
  position: relative;
  z-index: 2;
  margin-right: auto;
  margin-left: auto;
  @include media(pc) {
    max-width: 1092px + 52px + 52px + 48px;
  }
  
  @include media(sp) {
    overflow: hidden;
  }
  .slick-list {
    overflow: visible;
  }
}

.index-slider_item {
  @include media(pc) {
    margin-left: 26px;
    margin-right: 26px;
  }
  
  @include media(sp) {
    margin-left: sp(5);
    margin-right: sp(5);
  }
}

.index-slider_image {
  @include media(pc) {
    border-radius: 80px;
  }
  
  @include media(sp) {
    border-radius: sp(30);
  }
}

.index-slider_prev,
.index-slider_next {
  padding: 0;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color .2s ease;
  border: 0;
  background-color: #00B163;
  @include media(pc) {
    width: 62px;
    height: 62px;
    font-size: 40px;
  }
  
  @include media(sp) {
    width: sp(30);
    height: sp(30);
    font-size: sp(18);
  
  }

  &:hover {
    background-color: lighten(#00B163, 5%);
  }
}

.index-slider_prev {
  @include media(pc) {
    left: 44px;
  }
  
  @include media(sp) {
    left: sp(16);
  }

  &::before {
    font-family: 'icon-magonomise';
    content: '\e80b';
    color: #F7FF00;
    @include media(pc) {
    }
    
    @include media(sp) {
    }
  }
}
.index-slider_next {
  @include media(pc) {
    right: 44px;
  }
  
  @include media(sp) {
    right: sp(16);
  }

  &::before {
    font-family: 'icon-magonomise';
    content: '\e80c';
    color: #F7FF00;
    @include media(pc) {
    }
    
    @include media(sp) {
    
    }
  }
}

.index-logo {
  position: relative;
  line-height: 1.6;
  z-index: 2;
  @include media(pc) {
    display: flex;
    justify-content: space-between;
    max-width: 960px + 40px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  
  @include media(sp) {
    padding-left: sp(30);
    padding-right: sp(30);
    padding-bottom: sp(60);
  
  }
}
.index-logo_main {
  @include media(pc) {
    order: 2;
    margin-top: -100px;
    padding-left: 20px;
    padding-right: 20px;
  }
  @include media(1000px) {
    margin-top: calc(-70 / 1000 * 100vw);
  }
  
  @include media(sp) {
    margin-top: sp(-30);
    width: sp(270);
    margin-left: auto;
    margin-right: auto;
  }
}
.index-logo_main_image {
  @include media(pc) {
  
  }
  @include media(1000px) {
    width: calc(400 / 1000 * 100vw);
  }
  
  @include media(sp) {
  
  }
}
.index-logo_sub01 {
  font-weight: 700;
  @include media(pc) {
    margin-top: 70px;
    font-size: 18px;
    order: 1;
  }

  @include media(1000px) {
    margin-top: calc(50 / 1000 * 100vw);
    font-size: calc(18 / 1000 * 100vw);
  }

  @include media(sp) {
    text-align: center;
    font-size: sp(18);
  }
}
.index-logo_sub01_icon {
  @include media(pc) {
    margin-right: 10px;
  }

  @include media(1000px) {
    width: calc(40 / 1000 * 100vw);
  }
  
  @include media(sp) {
    display: block;
    margin: 0 auto sp(10);
  }
}
.index-logo_sub02 {
  font-weight: 700;
  @include media(pc) {
    margin-top: 70px;
    font-size: 18px;
    order: 3;
  }

  @include media(1000px) {
    margin-top: calc(50 / 1000 * 100vw);
    font-size: calc(18 / 1000 * 100vw);
  }
  
  @include media(sp) {
    text-align: center;
    font-size: sp(18);
  }
}

.index-anniversary {
  background-color: #00B163;
  color: #fff;
  position: relative;
  @include media(pc) {
    padding-top: 120px;
    padding-bottom: 140px;
  }
  @include media(960px) {
    padding-top: 70px;
    padding-bottom: 100px;
  }
  
  @include media(sp) {
    padding-top: sp(45);
    padding-bottom: sp(90);
  
  }
}
.index-anniversary_container {
  position: relative;
  z-index: 1;
  @include media(pc) {
    display: flex;
    max-width: 1280px + 40px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  @include media(960px) {
    display: block;
  }
  @include media(sp) {
    padding-left: sp(20);
    padding-right: sp(20);
  }
}
.index-anniversary_image {
  @include media(pc) {
    width: 58%;
    margin-right: 6%;
  }
  @include media(960px) {
    width: 60%;
    margin: 0 auto;
  }
  
  @include media(sp) {
  
  }
}
.index-anniversary_image_item {
  @include media(pc) {
    border-radius: 60px;
  }
  
  @include media(sp) {
    border-radius: sp(30);
  }
}
.index-anniversary_content {
  text-align: center;
  @include media(pc) {
    width: 36%;
  }
  @include media(960px) {
    width: 60%;
    margin: 40px auto 0;
  }
  
  @include media(sp) {
    margin-top: sp(30);
  
  }
}
.index-anniversary_content_message {
  @include media(pc) {
  
  }
  
  @include media(sp) {
  }
}
.index-anniversary_content_place {
  @include media(pc) {
    margin-top: 50px;
    font-size: 17px;
  }
  
  @include media(sp) {
    margin-top: sp(30);
    font-size: sp(17);
  }
}
.index-anniversary_content_shopname {
  font-weight: 700;
  @include media(pc) {
    margin-top: 15px;
    font-size: 30px;
    line-height: 1.4;
  }
  
  @include media(sp) {
    font-size: sp(22);
    margin-top: sp(14);
  }
  & > span {
    @include media(pc) {
      font-size: 40px;
    }
    
    @include media(sp) {
      font-size: sp(36);
    }
  }
}
.index-anniversary_content_body {
  line-height: 2.1;
  @include media(pc) {
    margin-top: 20px;
  }
  
  @include media(sp) {
    margin-top: sp(20);
  }
}
.index-anniversary_content_btn {
  display: flex;
  justify-content: center;
  @include media(pc) {
    margin-top: 40px;
  }
  
  @include media(sp) {
    margin-top: sp(48);
  }
}
.index-anniversary_content_btn_item {
  background-color: #F7FF00;
  color: #00B163;
  font-weight: 900;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media(pc) {
    border-radius: 22px;
    padding: 0 30px;
    height: 44px;
  }
  
  @include media(sp) {
    border-radius: sp(22);
    height: sp(44);
    padding: 0 sp(34);
  }

  &::after {
    font-family: 'icon-magonomise';
    content: '\e80a';
    @include media(pc) {
      margin-left: 18px;
    }
    
    @include media(sp) {
      margin-left: sp(18);
    }
  }

  @include hover-opacity(.8);
}

.index-news {
  background-color: #FFFFE9;
  position: relative;
  @include media(pc) {
    padding: 150px 0;
  }
  
  @include media(sp) {
    padding: sp(60) 0 sp(70);
  }
}
.index-news_menu {
  background-color: #fff;
  overflow: hidden;
  @include media(pc) {
    display: flex;
    border-radius: 40px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  }
  
  @include media(sp) {
    border-radius: sp(30);
    box-shadow: 0 sp(3) sp(6) rgba(0,0,0,0.16);
  }
}
.index-news_menu_image {
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}
.index-news_menu_image_item {
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}
.index-news_menu_content {
  line-height: 1.6;
  @include media(pc) {
    align-self: center;
    padding: 0 70px;
  }
  
  @include media(sp) {
    padding: sp(30) sp(30) sp(50);
    text-align: center;
  }
}
.index-news_menu_content_term {
  color: #828238;
  font-weight: 700;
  @include media(pc) {
    font-size: 20px;
  }
  
  @include media(sp) {
    font-size: sp(18);
  
  }
}
.index-news_menu_content_name {
  font-weight: 700;
  @include media(pc) {
    font-size: 34px;
  }
  
  @include media(sp) {
    font-size: sp(30);
  }
}
.index-news_menu_content_price {
  @include media(pc) {
  
  }
  
  @include media(sp) {
    font-size: sp(14);
  }
}
.index-news_menu_content_price_yen {
  font-weight: 700;
  @include media(pc) {
    font-size: 24px;
    
  }
  
  @include media(sp) {
    display: block;
    font-size: sp(22);
  }
}
.index-news_carendarHere {
  display: flex;
  justify-content: center;
  @include media(pc) {
    margin-top: 60px;
  }
  
  @include media(sp) {
    margin-top: sp(40);
  }
}
.index-news_carendarHere_btn {
  border: 1px solid #FFBB00;
  background-color: #fff;
  color: #828238;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  @include media(pc) {
    height: 80px;
    border-radius: 40px;
    font-size: 22px;
    padding: 0 60px;
  }
  
  @include media(sp) {
    height: sp(60);
    border-radius: sp(40);
    font-size: sp(18);
    padding: 0 sp(30);
    width: 100%;
  
  }

  @include hover-opacity();

  &::after {
    font-family: 'icon-magonomise';
    content: '\e80a';
    color: #FFBB00;
    @include media(pc) {
      font-size: 24px;
      margin-left: 24px;
    }
    
    @include media(sp) {
      margin-left: sp(20);
      font-size: sp(20);
    }
  }
}
.index-news_message {
  @include media(pc) {
    margin-top: 80px;
  }
  
  @include media(sp) {
    margin-top: sp(30);
  }
}
.index-news_message_term {
  line-height: 1.5;
  @include media(pc) {
    font-size: 20px;
    margin-bottom: 1.5rem;
  }
  
  @include media(sp) {
    font-size: sp(20);
    margin-bottom: .6rem;
  }
}
.index-news_message_desc {
  line-height: 2.1;
  @include media(pc) {
    margin-bottom: 45px;
  }
  
  @include media(sp) {
    margin-bottom: sp(30);
  }
}

.index-news_article {
  border-bottom: 1px dotted #C4C477;
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}
.index-news_article_item {
  border-top: 1px dotted #C4C477;
  line-height: 2;

  @include media(pc) {
    padding: 25px 0;
    display: flex;
  }
  
  @include media(sp) {
    padding: sp(16) 0;
  }
}
.index-news_article_time {
  font-weight: 700;
  color: #FFBB00;
  @include media(pc) {
    width: 120px;
  }
  
  @include media(sp) {
  
  }
}
.index-news_article_body {
  @include media(pc) {
    flex: 1;
  }
  
  @include media(sp) {
  
  }
}

.index-instagram {
  background-color: #fff;
  @include media(pc) {
    padding-top: 110px;
    padding-bottom: 150px;
  }
  @include media(960px) {
    padding-top: 60px;
    padding-bottom: 70px;
  }
  
  @include media(sp) {
    padding-top: sp(50);
    padding-bottom: sp(50);
  }
    
}
.index-instagram_header {
  @include media(pc) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 34px;
  }
  
  @include media(sp) {
  
  }
}
.index-instagram_header_anchor {
  text-decoration: none;
  color: #444;
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}
.index-instagram_header_title {
  @include media(pc) {
  
  }
  
  @include media(sp) {
    text-align: center;
  }
}
.index-instagram_header_title_logo {
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}
.index-instagram_header_title_desc {
  font-weight: 700;
  @include media(pc) {
    margin-top: 12px;
  }
  
  @include media(sp) {
    margin-top: sp(12);
  }
}
.index-instagram_header_follow {
  @include media(pc) {
    display: flex;
    width: 54%;
  
  }
  
  @include media(sp) {
    margin-top: sp(20);
  }
}
.index-instagram_header_follow_text {
  line-height: 1.86;
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}
.index-instagram_header_follow_qrcode {
  @include media(pc) {
    white-space: nowrap;
    min-width: 65px;
    margin-left: 20px;
  }
  
  @include media(sp) {
    display: none;
  }
}
.index-instagram_anchor {
  text-decoration: none;
  display: block;
}
.index-instagram_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include media(pc) {
    margin-left: -2%;
  }
  
  @include media(sp) {
    margin-top: sp(25);
  }
}
.index-instagram_list_item {
  @include media(pc) {
    width: 23%;
    margin-left: 2%;
    margin-bottom: 2%;
  }
  
  @include media(sp) {
    width: 47.5%;
    margin-bottom: 5%;
  }
}
.index-instagram_list_image {
  @include media(pc) {
    border-radius: 20px;
  }
  
  @include media(sp) {
    border-radius: sp(15);
  }
}
.index-calendar {
  background-color: #FFFFE9;
  position: relative;
  z-index: 3;
  @include media(pc) {
    padding-top: 120px;
    padding-bottom: 160px;
  }
  @include media(960px) {
    padding-top: 70px;
    padding-bottom: 120px;
  }
  
  @include media(sp) {
    padding-top: sp(50);
    padding-bottom: sp(70);
  
  }
}
.index-calendar_column {
  @include media(pc) {
    display: flex;
    align-items: center;
  }
  @include media(1080px) {
    display: block;
  }
  
  @include media(sp) {
  
  }
}
.index-calendar_main {
  @include media(pc) {
    width: 47%;
  }
  @include media(1080px) {
    width: 100%;
  }
  
  @include media(sp) {
  
  }
}
.index-calendar_body {
  line-height: 1.9;
  @include media(pc) {
    margin-top: -25px;
  }
  
  @include media(sp) {
  
  }
}
.index-calendar_content {
  @include media(pc) {
    width: 42%;
    margin-left: 11%;
  
  }
  @include media(1080px) {
    width: 100%;
    margin-left: 0;
    margin-top: 50px;
  }
  
  @include media(sp) {
    margin-top: sp(30);
  }

  iframe {
    width: 100%;
    @include media(pc) {
      height: 360px;
    }
    
    @include media(sp) {
      height: sp(360);
    }
  }
}

.index-calendar_list {
  font-family: 'Noto Sans JP', 'Yu Gothic Medium', '游ゴシック Medium', YuGothic, '游ゴシック体', 'ヒラギノ角ゴ Pro W3', sans-serif;
  line-height: 1.6;
  overflow-y: auto;
  @include media(pc) {
    height: 352px;

    // スクロールの幅の設定
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    // スクロールの背景の設定
    &::-webkit-scrollbar-track {
      border-radius: 150px;
      background-color: #E5E5E5;
    }

    // スクロールのつまみ部分の設定
    &::-webkit-scrollbar-thumb {
      border-radius: 150px;
      background: #FFBB00;
    }

  }

  @include media(sp) {
    height: sp(352);
  }

}
.index-calendar_term {
  background-color: #FFF3D4;
  @include media(pc) {
    padding: 10px 20px;
  }

  @include media(sp) {
    padding: sp(10) sp(20);
  }
}
.index-calendar_desc {
  background-color: #fff;
  @include media(pc) {
    padding: 10px 20px;
  }

  @include media(sp) {
    padding: sp(10) sp(20);

  }
}

.index-line {
  background-color: #fff;
  position: relative;
  z-index: 1;
  @include media(pc) {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  @include media(sp) {
    padding-top: sp(30);
    padding-bottom: sp(60);
  }
}
.index-line_columns {
  @include media(pc) {
    display: flex;
  }

  @include media(sp) {

  }
}
.index-line_image {
  @include media(pc) {
    flex: 1;
  }

  @include media(sp) {

  }
}
.index-line_main {
  @include media(pc) {
    width: 432px;
    margin-left: 60px;
  }

  @include media(sp) {

  }
}
.index-line_heading {
  text-align: center;
  @include media(pc) {
    margin-bottom: 30px;
    margin-top: 20px;
  }

  @include media(sp) {
    margin-bottom: sp(35);
    margin-top: sp(30);
  }
}
.index-line_account {
  display: flex;
  @include media(pc) {

  }

  @include media(sp) {

  }
}
.index-line_account_icon {
  @include media(pc) {
    margin-right: 40px;
    width: 103px;
  }

  @include media(sp) {
    width: sp(88);
    margin-right: sp(20);
  }
}
.index-line_account_info {
  @include media(pc) {
    width: 100%;
    flex: 1;
  }

  @include media(sp) {

  }
}
.index-line_account_name {
  font-weight: 700;
  color: #444444;
  text-align: center;
  @include media(pc) {
    font-size: 18px;
    margin-bottom: 15px;
    margin-top: 10px;
  }

  @include media(sp) {
    font-size: sp(16);
    margin-top: sp(5);
    margin-bottom: sp(15);
  }
}
.index-line_account_btn {
  display: flex;
  border: 2px solid #00B900;
  font-weight: 700;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  color: #00B900;
  width: 100%;
  transition: all .2s ease;
  @include media(pc) {
    height: 58px;
    border-radius: 29px;
    font-size: 20px;
  }

  @include media(sp) {
    height: sp(50);
    border-radius: sp(25);
    font-size: sp(18);
  }

  &::after {
    font-family: 'icon-magonomise';
    content: '\e80c';
    color: #FFCC00;
    @include media(pc) {
      margin-left: 20px;
    }

    @include media(sp) {
      margin-left: sp(20);
    }
  }

  &:hover {
    background-color: #00B900;
    color: #fff;
  }
}
.index-line_body {
  color: #444444;
  line-height: 1.6;
  @include media(pc) {
    font-size: 15px;
    margin-top: 35px;
  }

  @include media(sp) {
    margin-top: sp(30);
    font-size: sp(16);
  }
}
.index-line_qr {
  @include media(pc) {
    float: right;
    margin-top: 35px;
    margin-left: 20px;
  }

  @include media(sp) {
    display: none;
  }
}

.index-magosweets {
  background-color: #FFF6DE;
  position: relative;
  z-index: 1;
  @include media(pc) {
    padding-top: 46px;
    padding-bottom: 125px;
  }

  @include media(sp) {
    padding-top: sp(30);
    padding-bottom: sp(75);
  }
}

.index-magosweets_heading {
  text-align: center;
  @include media(pc) {

  }

  @include media(sp) {
    margin-left: sp(-20);
    margin-right: sp(-20);

  }

  img {
    @include media(pc) {

    }

    @include media(sp) {
      width: 100%;
    }
  }
}
.index-magosweets_slider {
  @include media(pc) {
    margin-top: 30px;
    margin-bottom: 60px;
  }

  @include media(sp) {
    margin-top: sp(10);
    margin-bottom: sp(40);
  }
}
.index-magosweets_body {
  text-align: center;
  line-height: 1.8;
  font-weight: 700;
  color: #826738;
  @include media(pc) {
    font-size: 26px;
  }

  @include media(sp) {
    font-size: sp(18);
  }
}

.index-access {
  background-color: #fff;
  position: relative;
  @include media(pc) {
    padding-top: 170px;
    padding-bottom: 150px;
  }
  @include media(960px) {
    padding-top: 90px;
    padding-bottom: 120px;
  }
  
  @include media(sp) {
    padding-top: sp(50);
    padding-bottom: sp(70);
  
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    background-color: #00B163;
    @include media(pc) {
      border-radius: 40%;
      height: 660px;
      width: 120%;
      transform: translate(-50%, -40%);
    }
    
    @include media(sp) {
      border-radius: 50%;
      height: sp(520);
      width: 180%;
      transform: translate(-50%, -40%);
    }
  }
}

.index-access_container {
  position: relative;
  z-index: 3;
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.index-access_map {
  overflow: hidden;
  @include media(pc) {
    border-radius: 30px;
  }
  
  @include media(sp) {
    border-radius: sp(30);
  }
  iframe {
    width: 100%;
    @include media(pc) {
      height: 530px;
    }
    
    @include media(sp) {
      height: sp(335);
    }
  }
}

.index-access_info {
  @include media(pc) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 50px;
  }
  
  @include media(sp) {
    margin-top: sp(30);
  }
}
.index-access_info_main {
  line-height: 1.8;
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}
.index-access_info_main_title {
  color: #00B163;
  font-weight: 700;
  text-align: center;
  @include media(pc) {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  @include media(sp) {
    font-size: sp(20);
    margin-bottom: sp(10);
  
  }
}
.index-access_info_main_title_name {
  @include media(pc) {
    font-size: 34px;
  }
  
  @include media(sp) {
    font-size: sp(32);
  }
}
.index-access_info_main_body {
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}
.index-access_info_main_body_tel {
  font-weight: 700;
  @include media(pc) {
    font-size: 20px;
  }
  
  @include media(sp) {
  
  }
}
.index-access_info_image {
  overflow: hidden;
  @include media(pc) {
    border-radius: 28px;
    width: 38%;
    margin-left: 30px;
  }
  
  @include media(sp) {
    margin-top: sp(25);
    border-radius: sp(28);
  }
}
.index-access_info_main_link {
  margin-top: 1rem;
  @include media(pc) {
    
    padding-left: 1.6rem;
    text-indent: -1.6rem;
  }
  
  @include media(sp) {
    padding-left: 1.5rem;
    text-indent: -1.5rem;
  }
}
.index-access_info_main_link_text {
  text-decoration: underline;
  color: #444;
  margin-left: .5rem;
  margin-right: .5rem;
  @include media(pc) {
  }
  
  @include media(sp) {
    word-wrap: break-word;
  }
}



.index-access_info_main_link_icon {
  color: #FFBB00;
  font-family: 'icon-magonomise';
  word-wrap: break-word;

  @include media(pc) {
    font-size: 18px;
  }
  
  @include media(sp) {
  
  }
}

.index-faq {
  background-color: #FFFFE9;
  @include media(pc) {
    padding-top: 160px;
    padding-bottom: 150px;
  }
  
  @include media(960px) {
    padding-top: 80px;
    padding-bottom: 80px;
  
  }
  
  @include media(sp) {
    padding-top: sp(50);
    padding-bottom: sp(70);
  }
}
.index-faq_list {
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}
.index-faq_list_item {
  background-color: #FFFFFF;
  line-height: 1.8;

  @include media(pc) {
    border-radius: 40px;
    box-shadow: 0 3px 3px rgba(#B8B586,.16);
  }
  
  @include media(sp) {
    border-radius: sp(20);
    box-shadow: 0 sp(3) sp(3) rgba(#B8B586,.16);
  
  }

  & + & {
    @include media(pc) {
      margin-top: 20px;
    }
    
    @include media(sp) {
      margin-top: sp(10);
    }
  }
}
.index-faq_list_item_q {
  position: relative;
  cursor: pointer;
  @include media(pc) {
    padding: 25px 70px;
    font-size: 18px;
  }
  
  @include media(sp) {
    padding: sp(22) sp(60);
    font-size: sp(18);
    line-height: 1.7;
  }

  &::before {
    position: absolute;
    content:'Q';
    color: #FFBB00;
    font-weight: 700;
    @include media(pc) {
      top: 17px;
      left: 35px;
      font-size: 26px;
    }
    
    @include media(sp) {
      top: sp(14);
      left: sp(25);
      font-size: sp(26);
    }
  }

  &::after {
    position: absolute;
    content: '\e80e';
    color: #FFBB00;
    font-weight: 700;
    font-family: 'icon-magonomise';
    @include media(pc) {
      top: 12px;
      right: 35px;
      font-size: 28px;
    }
    
    @include media(sp) {
      top: sp(17);
      right: sp(25);
      font-size: sp(24);
    
    }
  }

  &.is-open {
    @include media(pc) {
    
    }
    
    @include media(sp) {
    
    }

    &::after {
      content: '\e80f';
      @include media(pc) {
      }
      
      @include media(sp) {
      
      }
    }
  }
}
.index-faq_list_item_a {
  display: none;
  @include media(pc) {
    padding: 0 35px 20px;
    font-size: 18px;
  }
  
  @include media(sp) {
    padding: 0 sp(30) sp(22) sp(30);
    font-size: sp(18);
  }
}

.index-faq_list_item_a_content {
  position: relative;
  @include media(pc) {
    padding-left: 35px;
    padding-right: 35px;
  }

  @include media(sp) {
    padding-left: sp(35);
    padding-right: sp(20);
  }

  &::before {
    position: absolute;
    content:'A';
    color: #00B163;
    font-weight: 700;
    @include media(pc) {
      top: -8px;
      left: 3px;
      font-size: 26px;
    }

    @include media(sp) {
      top: sp(-5);
      left: 0;
      font-size: sp(26);
    }
  }
}

.index-aboutus {
  // background-color: #00B163;
  background-color: #fff;
  position: relative;
  @include media(pc) {
    padding: 0 0 200px;
  }
  @include media(960px) {
    padding: 0 0 160px;
  }
  
  @include media(sp) {
  
  }
}

.index-aboutus_bg {
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  &::after {
    width: 100%;
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url('../media/index/aboutus_bg.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @include media(pc) {
      height: 120px;
    }
    @include media(sp) {
      height: sp(30);
    }
  }
}

.index-aboutus_bg_image {
  width: 100%;
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.index-aboutus_container {
  position: relative;
  z-index: 5;
  background-color: #00B163;
  @include media(pc) {
    padding-top: 80px;
    padding-bottom: 300px;
  }
  
  @include media(sp) {
    padding-top: sp(50);
    padding-bottom: sp(60);
  
  }
}

.index-aboutus_message {
  line-height: 1.5;
  text-align: center;
  font-weight: 700;
  color: #fff;
  @include media(pc) {
    font-size: 40px;
  }
  
  @include media(sp) {
    font-size: sp(30);
  }
}

.index-aboutus_department {
  line-height: 1.6;
  color: #fff;
  text-align: center;
  @include media(pc) {
    margin-top: 30px;
  }
  
  @include media(sp) {
    margin-top: sp(40);
  }
}

.index-aboutus_name {
  line-height: 1.6;
  text-align: center;
  color: #fff;
  font-weight: 700;
  @include media(pc) {
    font-size: 24px;
    margin-top: 10px;
  }
  
  @include media(sp) {
    font-size: sp(22);
    margin-top: sp(10);
  }
}

.index-aboutus_wrapper {
  @include media(pc) {
  
  }
  
  @include media(sp) {
    padding-bottom: sp(100);
  }
}

.index-aboutus_content {
  background-color: #fff;
  position: relative;
  z-index: 8;
  @include media(pc) {
    border-radius: 44px;
    padding: 75px 150px;
    margin-top: -250px;
  }
  
  @include media(960px) {
    padding: 75px 70px;
  }
  
  @include media(sp) {
    padding-top: sp(50);
  }
}
.index-aboutus_content_heading {
  font-weight: 700;
  text-align: center;
  @include media(pc) {
    font-size: 22px;
    margin-bottom: 40px;
  }
  
  @include media(sp) {
    font-size: sp(22);
    margin-bottom: sp(28);
  }

  &::after {
    content: '';
    background-color: #00B163;
    display: block;
    margin-left: auto;
    margin-right: auto;
    @include media(pc) {
      width: 40px;
      height: 5px;
      border-radius: 5px;
      margin-top: 18px;
    }
    
    @include media(sp) {
      width: sp(40);
      height: sp(5);
      border-radius: sp(5);
      margin-top: sp(18);
    
    }
  }
}
.index-aboutus_content_item {
  @include media(pc) {
    display: flex;
    align-items: flex-start;
  }
  
  @include media(sp) {
  
  }

  & + .index-aboutus_content_heading {
    @include media(pc) {
      margin-top: 110px;
    }
    
    @include media(sp) {
      margin-top: sp(40);
    }
  }
}
.index-aboutus_content_item-reverse {
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
  .index-aboutus_content_item_image {
    @include media(pc) {
      order: 2;
      margin-left: 20px;
    }
    
    @include media(sp) {
    
    }
  }
  .index-aboutus_content_item_text {
    @include media(pc) {
      order: 1;
      margin-left: 0;
    }
    
    @include media(sp) {
    }
  }
}
.index-aboutus_content_item_image {
  overflow: hidden;
  @include media(pc) {
    border-radius: 20px;
  }
  
  @include media(sp) {
    border-radius: sp(15);
  }
}
.index-aboutus_content_item_text {
  line-height: 1.8;
  @include media(pc) {
    flex: 1;
    margin-left: 20px;
  }
  
  @include media(sp) {
    margin-top: sp(20);
  }
}

.index-aboutus_here {
  display: flex;
  justify-content: center;
  @include media(pc) {
    margin-top: 110px;
  }
  
  @include media(sp) {
    margin-top: sp(55);
  }
}
.index-aboutus_here_btn {
  font-weight: 700;
  background-color: #00B163;
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  
  @include media(pc) {
    font-size: 20px;
    height: 90px;
    border-radius: 34px;
    padding: 0 75px;
  }
  
  @include media(sp) {
    font-size: sp(20);
    height: sp(90);
    border-radius: sp(20);
    padding: 0 sp(50) 0 sp(35);
    line-height: 1.5;
    width: 100%;
  }

  @include hover-opacity();
}
.index-aboutus_here_icon {
  color: #F7FF00;
  font-family: 'icon-magonomise';
  @include media(pc) {
    font-size: 23px;
    margin-left: 20px;
  }
  
  @include media(sp) {
    position: absolute;
    right: sp(35);
    top: 50%;
    transform: translateY(-50%);
  }
}



.deco {
  position: absolute;
  @include media(pc) {
  
  }
  @include media(1080px) {
    transform: scale(.5);
  }
  
  @include media(sp) {
    
  }
}

.deco_image {
  width: 100%;
  height: auto;
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.deco-01 {
  z-index: 2;
  @include media(pc) {
    left: -50px;
    top: 140px;
  }

  @include media(sp) {
    width: sp(76);
    top: sp(190);
    left: sp(-36);
  }
}
.deco-02 {
  z-index: 2;
  @include media(pc) {
    right: -20px;
    top: 110px;
  }

  @include media(sp) {
    width: sp(80);
    top: sp(190);
    right: sp(-36);
  }
}
.deco-03 {
    z-index: 1;
    @include media(pc) {
    left: 300px;
    top: 60px;
    width: 200px;
  }

  @include media(sp) {
    width: sp(136);
    top: 0;
    left: sp(-80);
  }
}

.deco-04 {
  z-index: 1;
  @include media(pc) {
    right: 140px;
    top: 100px;
  }
  @include media(1080px) {
    right: 0;
    top: 20px;
  }

  @include media(sp) {
    width: sp(136);
    top: sp(30);
    right: sp(-20);
  }
}
.deco-05 {
  @include media(pc) {
    left: -100px;
    bottom: -80px;
  }

  @include media(sp) {
    width: sp(96);
    left: sp(-20);
    bottom: sp(-20);
  }
}

.deco-06 { 
  @include media(pc) {
    right: -140px;
    top: 0;
  }

  @include media(sp) {
    width: sp(110);
    top: sp(-50);
    right: sp(-20);

  }
}

.deco-07 {
  z-index: 1;
  @include media(pc) {
    left: -80px;
    top: 0;
  }

  @include media(sp) {
    width: sp(110);
    top: sp(-10);
    left: sp(-30);
    transform: rotate(-120deg);
  }
}

.deco-08 {
  z-index: 1;
  @include media(pc) {
    right: -100px;
    top: 30px;
    width: 200px;
  }

  @include media(sp) {
    width: sp(110);
    top: sp(140);
    right: sp(-30);
  }
}

.deco-09 {
  z-index: 1;
  @include media(pc) {
    left: -70px;
    top: 150px;
  }

  @include media(sp) {
    width: sp(80);
    top: 50%;
    left: sp(-24);

  }
}

.deco-10 {
  z-index: 1;
  @include media(pc) {
    right: -180px;
    top: 0;
  }

  @include media(sp) {
    width: sp(76);
    top: 0;
    right: sp(-40);
  }
}

.deco-11 {
  z-index: 1;
  @include media(pc) {
    right: -180px;
    top: 400px;
  }

  @include media(sp) {
    width: sp(64);
    bottom: 0;
    right: sp(-30);
  }
}

.deco-12 {
  z-index: 1;
  @include media(pc) {
    left: -80px;
    top: 0;
  }

  @include media(sp) {
    width: sp(70);
    left: sp(-20);
    top: sp(40);
  }
}

.deco-13 {
  z-index: 1;
  @include media(pc) {
    right: -180px;
    bottom: -100px;
  }
  
  @include media(1080px) {
    transform: scale(.5);
    right: -200px;
    bottom: -140px;
  }

  @include media(sp) {
    width: sp(50);
    top: sp(80);
    right: sp(-15);
  }
}
