@use "global" as *;

.l-header {
  // background-color: #00B163;
  position: relative;

  @include media(pc) {

  }

  @include media(sp) {

  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 50%;
    background-color: #00B163;
    @include media(pc) {
      border-radius: 40%;
      height: 660px;
      width: 120%;
      transform: translate(-50%, -40%);
    }
    @include media(1080px) {
      border-radius: 40%;
      height: 500px;
      width: 140%;
      transform: translate(-50%, -40%);
      
    } 
    
    @include media(sp) {
      height: sp(520);
      border-radius: 50%;
      width: 200%;
      transform: translate(-50%, -40%);
    }
  }
}

.l-header_catchcopy {
  text-align: center;
  position: relative;
  z-index: 2;
  @include media(pc) {
    padding: 35px 150px 30px;
  }
  
  @include media(sp) {
    padding: sp(27) 0 sp(15);
    margin-left: sp(-30);
    margin-right: sp(-30);
    
  }
}

.l-header_sns {
  position: absolute;
  display: flex;
  z-index: 2;
  @include media(pc) {
    top: 30px;
    right: 30px;
  }
  
  @include media(sp) {
    top: sp(12);
    right: sp(12);
  
  }
}
.l-header_sns_item {
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }

  & + & {
    @include media(pc) {
      margin-left: 15px;
    }
    
    @include media(sp) {
      margin-left: sp(10);
    
    }
  }
}
.l-header_sns_anchor {
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }

  @include hover-opacity();
}
.l-header_sns_image {
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}
