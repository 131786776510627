@use "global" as *;

html {
  background: #00B163;
  color: #444;
  font-family: 'LINESeedJP', 'Noto Sans JP', 'Yu Gothic Medium', '游ゴシック Medium', YuGothic, '游ゴシック体', 'ヒラギノ角ゴ Pro W3', sans-serif;
  overflow-x: hidden;

  @include media(pc) {
    font-size: 16px;
  }

  @include media(sp) {
    font-size: sp(16);
  }


  &.is-opening {
    overflow: hidden;
  }
}

body {
  overflow: hidden;
}
