@use "global" as *;


.l-navTrigger {
  background-color: #009B57;
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  transition: all .5s ease;
  z-index: 1000;
  @include media(pc) {
    width: 82px;
    height: 82px;
    top: 30px;
    left: 30px;
  }
  
  @include media(sp) {
    width: sp(35);
    height: sp(35);
    top: sp(10);
    left: sp(10);
  
  }
}
.l-navTrigger_container,
.l-navTrigger_line {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
}
.l-navTrigger_container {
  position: relative;
  background: none;
  border: none;
  appearance: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include media(pc) {
    width: 45px;
    height: 32px;
  }
  
  @include media(sp) {
    width: sp(20);
    height: sp(14);
  
  }
}
.l-navTrigger_line {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: #fff;
  @include media(pc) {
    height: 4px;
  }
  
  @include media(sp) {
    height: sp(2);
  }
}
.l-navTrigger_line:nth-of-type(1) {
  top: 0;
}
.l-navTrigger_line:nth-of-type(2) {
  @include media(pc) {
    top: 14px;
  }
  
  @include media(sp) {
    top: sp(6);
  }
}
.l-navTrigger_line:nth-of-type(3) {
  bottom: 0;
}

.l-navTrigger.is-open {
  @include media(pc) {
    top: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
  }
  
  @include media(sp) {
  
  }
}
.l-navTrigger.is-open .l-navTrigger_container {
  @include media(pc) {
    width: 22px;
  }
  
  @include media(sp) {
  
  }
}
.l-navTrigger.is-open .l-navTrigger_line {
  @include media(pc) {
    height: 2px;
  }
  
  @include media(sp) {
  
  }
}
.l-navTrigger.is-open span:nth-of-type(1) {
  @include media(pc) {
    transform: translateY(15px) rotate(-45deg);
  }
  
  @include media(sp) {
    transform: translateY(sp(6.5)) rotate(-45deg);
  }
}
.l-navTrigger.is-open span:nth-of-type(2) {
  opacity: 0;
}
.l-navTrigger.is-open span:nth-of-type(3) {
  @include media(pc) {
    transform: translateY(-15px) rotate(45deg);
  }
  
  @include media(sp) {
    transform: translateY(sp(-6.5)) rotate(45deg);
  }
}


.l-nav {
  position: fixed;
  top: 0;
  left: -100%;
  background-color: #009B57;
  color: #fff;
  z-index: 900;
  height: 100vh;
  transition: left .4s ease;
  
  @include media(pc) {
    width: 375px;
  }
  
  @include media(sp) {
    width: 100%;
  }

  &.is-open {
    left: 0;
    @include media(pc) {
    
    }
    
    @include media(sp) {
    
    }
  }
}
.l-nav_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}
.l-nav_list {
  @include media(pc) {
    margin: 0 25px;
  }
  
  @include media(sp) {
    margin: 0 sp(30);
  }
}
.l-nav_list_item {
  font-weight: 700;
  @include media(pc) {
    font-size: 20px;
  }
  
  @include media(sp) {
    font-size: sp(20);
  }
}
.l-nav_list_anchor {
  color: #fff;
  display: block;
  border-bottom: 1px solid #62D2A1;
  text-align: center;
  text-decoration: none;
  transition: background-color .2s ease;
  @include media(pc) {
    padding: 25px 0;
  }
  
  @include media(sp) {
    padding: sp(22) 0;
  
  }
  &:hover {
    background-color: lighten(#009B57, 5%);
    @include media(pc) {
    
    }
    
    @include media(sp) {
    
    }
  }
}
.l-nav_sns {
  display: flex;
  justify-content: center;
  @include media(pc) {
    margin: 28px 25px 35px;
  
  }
  
  @include media(sp) {
    margin: sp(28) sp(25) sp(35);
  }
}
.l-nav_sns_item {
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }

  & + & {
    @include media(pc) {
      margin-left: 30px;
    }
    
    @include media(sp) {
      margin-left: sp(30);
    }
  }
}
.l-nav_sns_item {
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }

  @include hover-opacity;
}
.l-nav_link {
  color: #fff;
  border: 1px solid #62D2A1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  transition: all .2s ease;
  @include media(pc) {
    margin: 0 25px;
    height: 62px;
  }
  
  @include media(sp) {
    font-size: sp(16);
    height: sp(62);
    margin: 0 sp(25);
  }

  &:hover {
    background-color: #fff;
    color: #009B57;
  }

  & + & {
    @include media(pc) {
      margin-top: 30px;
    }
    
    @include media(sp) {
    
    }
  }
}