@use "global" as *;

.l-footer {

  background-color: #00B163;
  text-align: center;
  position: relative;
  color: #fff;

  @include media(pc) {
    padding-bottom: 90px;
  }

  @include media(sp) {
    padding-bottom: sp(60);
  }
}

.l-footer_blank {
  height: 1px;
  @include media(pc) {
    
  }
  
  @include media(sp) {
  
  }
}

.l-footer_logo {
  position: relative;
  z-index: 2;
  pointer-events: none;
  @include media(pc) {
    margin-top: -90px;
  }
  
  @include media(sp) {
    margin-top: sp(-45);
    width: sp(280);
    margin-left: auto;
    margin-right: auto;
  }
}

.l-footer_pagetop {
  cursor: pointer;
  position: absolute;
  top: 0;
  font-family: 'icon-magonomise';
  background-color: #FFBB00;
  border-radius: 50%;
  display: flex;
  align-items: center;
  margin-left: auto;
  justify-content: center;
  color: #fff;
  transform: translateY(-50%);
  text-decoration: none;
  @include hover-opacity();
  @include media(pc) {
    width: 54px;
    height: 54px;
    font-size: 41px;
    right: 100px;
  }
  @include media(960px) {
    right: 30px;
  }
  
  @include media(sp) {
    width: sp(36);
    height: sp(36);
    font-size: sp(28);
    right: sp(10);
  
  }
}

.l-footer_pagetop_icon {
  transform: translateY(-5%);
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.l-footer_title {
  font-weight: 700;
  line-height: 1.8;
  @include media(pc) {
    font-size: 20px;
  }
  
  @include media(sp) {
    font-size: sp(20);
  }
}

.l-footer_title_name {
  line-height: 1.8;
  @include media(pc) {
    font-size: 29px;
  }
  
  @include media(sp) {
    font-size: sp(29);
  }
}

.l-footer_department {
  line-height: 1.8;
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.l-footer_address {
  line-height: 1.8;
  @include media(pc) {
    margin-top: 20px;
  }
  
  @include media(sp) {
    margin-top: sp(20);
  }
}

.l-footer_sns {
  display: flex;
  justify-content: center;
  @include media(pc) {
    margin-top: 45px;
  }
  
  @include media(sp) {
    margin-top: sp(30);
  }
}

.l-footer_sns_item {
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }

  & + & {
    @include media(pc) {
      margin-left: 30px;
    }
    
    @include media(sp) {
      margin-left: sp(30);
    }
  }
}

.l-footer_sns_anchor {
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}

.l-footer_copy {
  font-weight: 300;
  @include media(pc) {
    margin-top: 30px;
    font-size: 14px;
  }
  
  @include media(sp) {
    margin-top: sp(30);
    font-size: sp(14);
  }
}
