@use "global" as *;

.m-container {
  @include media(pc) {
    max-width: 960px + 40px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  @include media(960px) {
    max-width: 960px + 80px;
    padding-left: 40px;
    padding-right: 40px;
  }

  @include media(sp) {
    padding-left: sp(20);
    padding-right: sp(20);
  }
}

.m-browserOverlay {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 99998;
  @include media(pc) {

  }

  @include media(sp) {

  }
}
.m-browser {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 2;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media(pc) {

  }

  @include media(sp) {
    margin: 0 auto;
  }
}
.m-browser_container {
  background-color: #fff;
  padding: 3rem;
  @include media(pc) {

  }

  @include media(sp) {

  }
}
.m-browser_message {
  text-align: left;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.m-heading {
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  @include media(pc) {
    margin-bottom: 60px;
  }
  
  @include media(sp) {
    margin-bottom: sp(30);
  }
}
.m-heading_en {
  color: #FFBB00;
  @include media(pc) {
    font-size: 32px;
  }
  
  @include media(sp) {
    font-size: sp(24);
  }
}
.m-heading_main {
  color: #828238;
  line-height: 1.6;
  border-left: 1px solid #C4C477;
  @include media(pc) {
    font-size: 26px;
    margin-left: 20px;
    padding-left: 20px;
  }
  
  @include media(sp) {
    font-size: sp(20);
    margin-left: sp(20);
    padding-left: sp(20);
  
  }
}
.m-heading_en-bgGreen {
  color: #F7FF00;
  @include media(pc) {
  
  }
  
  @include media(sp) {
    font-size: sp(20);
  }
}
.m-heading_main-bgGreen {
  color: #fff;
  border-left: 1px solid #FFFFFF;
  @include media(pc) {
  
  }
  
  @include media(sp) {
    font-size: sp(18);
  }
}

.m-border-wave {
  position: relative;
  &::before {
    content: '';
    z-index: 5;
    position: absolute;
    display: block;
    background-repeat: repeat-x;
    background-size: cover;
    left: 0;
    width: 100%;
    @include media(pc) {
      height: 26px;
      top: -26px;
    }
    
    @include media(sp) {
      height: sp(20);
      top: sp(-20);
    
    }
  }
}

.m-border-wave-bottom {
  &::before {
    bottom: 0;
    top: auto;
    @include media(pc) {
    }
    
    @include media(sp) {
    
    }
}
}

.m-border-wave-yellow {
  &::before {
    background-image: url('../media/index/border_wave_yellow.svg');
  }
}

.m-border-wave-green {
  &::before {
    background-image: url('../media/index/border_wave_green.svg');
  }
}

.m-border-wave-white {
  &::before {
    background-image: url('../media/index/border_wave_white.svg');
  }
}

.js-inview {
  opacity: 0;
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .3s;

  @include media(pc) {
    transform: translateY(50px);
  }
  
  @include media(sp) {
  
  }

  &.is-inview {
    opacity: 1;
    @include media(pc) {
      transform: translateY(0);
    }
    
    @include media(sp) {
    
    }
  }
}

.m-loading {
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }
}
.m-loadingContent {
  visibility: hidden;
  @include media(pc) {
  
  }
  
  @include media(sp) {
  
  }

  &.is-show {
    visibility: visible;
  }
}

#animation_container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00B163;
  @include media(pc) {
  
  }
  
  @include media(sp) {
    
  }
  #canvas {
    transition: transform .4s ease-in, opacity .2s ease-in;
    opacity: 1;
  }

  &.is-done {
    #canvas {
      transform: scale(.3) translateY(-60vh);
      opacity: 0;
    }
  }
}

.loader {
  position: fixed;
  z-index: 9999999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}
@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  45% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0.7; }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }
@keyframes scale {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  45% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0.7; }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }

.ball-pulse > div:nth-child(1) {
  -webkit-animation: scale 1s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
          animation: scale 1s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08); }

.ball-pulse > div:nth-child(2) {
  -webkit-animation: scale 1s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
          animation: scale 1s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08); }

.ball-pulse > div:nth-child(3) {
  -webkit-animation: scale 1s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
          animation: scale 1s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08); }

.ball-pulse > div {
  background-color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: inline-block;
}